const prefix = '@stores';

export const StoreActionType = {
  GetTopStores: `${prefix}/GET_TOP_STORES`,
  GetTopStoresWithCoupons: `${prefix}/GET_TOP_STORES_WITH_COUPONS`,
  GetFeaturedStores: `${prefix}/GET_FEATURED_STORES`,
  GetStoreBySlug: `${prefix}/GET_STORE_BY_SLUG`,
  GetExpiredCoupons: `${prefix}/GET_EXPIRED_COUPONS`,
  GetStoreCoupons: `${prefix}/GET_STORE_COUPONS`,
  GetUserStoreRating: `${prefix}/GET_USER_STORE_RATING`,
  GetAllStores: `${prefix}/GET_ALL_STORES`,
  GetStorePromotions: `${prefix}/GET_STORE_PROMOTIONS`,
  PostRateStore: `${prefix}/POST_RATE_STORE`,
};
