import {
  DealDispatcherPayload,
  DealVoteAction,
  SaveDealAction,
} from '@pelando/components';

import { RemoveVoteVariables } from '../../../../../../infra/api/votes/types/RemoveVote';
import { VoteVariables } from '../../../../../../infra/api/votes/types/Vote';

export type useMutationActionHandlerProps = {
  onVote: (variables: VoteVariables) => void;
  onRemoveVote: (variables: RemoveVoteVariables) => void;
  recommendationId?: string;
};

export default function useMutationActionHandler({
  onVote,
  onRemoveVote,
  recommendationId,
}: useMutationActionHandlerProps) {
  return (
    action: DealVoteAction | SaveDealAction,
    payload: DealDispatcherPayload<DealVoteAction>
  ) => {
    switch (action) {
      case DealVoteAction.REMOVE_VOTE:
        onRemoveVote(
          payload as DealDispatcherPayload<DealVoteAction.REMOVE_VOTE>
        );
        break;
      case DealVoteAction.VOTE: {
        onVote({
          ...(payload as DealDispatcherPayload<DealVoteAction.VOTE>),
          options: { recommendationId },
        });
        break;
      }
      default:
        break;
    }
  };
}
