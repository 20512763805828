import { PAGE_INFO_FRAGMENT } from '../../offers/queries/shared';
import { NOTIFICATION_FRAGMENT } from '../fragments/notificationFragment';

export const NOTIFICATIONS_QUERY = `
  query NotificationsQuery($limit: Int, $after: String) {
    me {
      notifications(limit: $limit, after: $after, type: ALL_BUT_ALERTS_AND_CHAT) {
        edges {
          ...NotificationFragment
        }
        pageInfo {
          ...PageInfoFragment
        }
      }
    }
  }

  ${NOTIFICATION_FRAGMENT}
  ${PAGE_INFO_FRAGMENT}
`;
