export const COMMUNITY_BY_SLUG_QUERY = `
query CommunityBySlugQuery($slug: String!) {
        public{
          communityBySlug(slug: $slug) {
            id
            name
            slug
            description
            color
            image
            genderPreference
          }
    }
}
`;
