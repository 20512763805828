import {
  Avatar,
  Colors,
  Dropdown,
  HEADER_HEIGHT,
  Icon,
  MediaQuery,
  Theme,
} from '@pelando/components';
import {
  AvatarContainer,
  AvatarImage,
} from '@pelando/components/components/Avatar/style';
import { keyframes, styled } from 'styled-components';

export const UserMenuContainer = styled.div`
  --distance-header-user-menu: 54px; // Altura exata entre o header e o user menu

  display: flex;
  align-items: center;
  position: relative;
`;

export const UserMenuAvatar = styled(Avatar)`
  ${AvatarImage} {
    border: 2px solid rgb(${Theme.colors.Lima});
  }
`;

export const UserMenuAvatarIcon = styled(Icon)`
  font-size: 24px;
  color: rgb(${Theme.colors.Lima});
`;

const menuSlideLeftAnimation = keyframes`
 0% { left: -100%; display: block; opacity: 0 }
 100% { left: 0; opacity: 1}
`;

export const CustomDropdownList = styled.div`
  position: fixed;
  top: 0;
  left: -100%;
  width: 85%;
  height: 100dvh;
  background-color: rgb(${Theme.colors.Juliet});
  border-radius: 0px 0px 8px 8px;
  scrollbar-width: thin;
  scrollbar-color: rgb(246, 246, 246) rgb(136, 136, 136);
  overflow: auto;
  border: 1px solid rgb(${Theme.colors.Golf});

  &[data-opened='false'] {
    display: none;
  }

  &[data-opened='true'] {
    animation-name: ${menuSlideLeftAnimation};
    animation-duration: 0.3s;
    left: 0;
  }

  @media ${MediaQuery.SCREEN_MD_UP} {
    position: absolute;
    top: var(--distance-header-user-menu);
    width: 276px;
    max-height: 80vh;
    height: auto;
    right: 0;
    left: unset;

    &[data-opened='true'] {
      left: unset;
      display: block;
      animation: none;
      top: var(--distance-header-user-menu);
    }
  }
`;

export const CustomDropdownRoot = styled(Dropdown.Root)`
  @media ${MediaQuery.SCREEN_SM} {
    margin-bottom: 0px;
  }
`;

export const CustomDropdownTriggerButton = styled.button`
  background: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  ${AvatarContainer} {
    width: 40px;
    height: 40px;
  }
`;

export const Overlay = styled.div`
  position: fixed;
  background-color: rgb(${Colors.Black}, 0.5);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;

  &[data-opened='false'] {
    display: none;
  }
  @media ${MediaQuery.SCREEN_MD_UP} {
    top: ${HEADER_HEIGHT}px;
  }
`;
