import styled from 'styled-components';

export const StepProgressBarContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const StepProgressStepCircle = styled.span`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 9px;
`;
