import { Store } from '../../../domain/entities/Store';
import { FeaturedStores } from '../../../domain/entities/Stores';
import { StoreRatingQueryResponse } from './types/IGetStoreRating';
import {
  StoreRateMutationResponse,
  StoreRateMutationParams,
} from './types/IRateStore';
import {
  FeaturedStoresResponse,
  FeaturedStoresVariables,
  StoreBySlugQueryResponse,
} from './types/IStoreBySlugQueryDTO';
import { StoreCouponsResponse } from './types/IStoreCoupons';
import {
  StoreCoupons,
  StoreCouponsQueryVariables,
} from './types/IstoreCouponsQueryDTO';
import {
  StorePromotionsQueryResponse,
  StorePromotionsQueryVariables,
  StorePromotions,
  StorePromotionsPageInfo,
} from './types/IstorePromotionsQueryDTO';
import USER_STORE_RATE_MUTATION from './mutations/rateStore';
import FEATURED_STORES_QUERY from './queries/featuredStoresQuery';
import USER_STORE_RATING_QUERY from './queries/getStoreRating';
import { STORE_SLUG_QUERY } from './queries/storeBySlugQuery';
import { STORE_COUPONS_QUERY } from './queries/storeCoupons';
import { STORE_PROMOTIONS_QUERY } from './queries/storePromotionsQuery';
import { createNewEndpoint } from '../core/endpoint';
import { GraphQlMethods } from '../core/fetch';
import { StoreActionType } from './actionTypes';
import { GetDealsStoreCache } from './types/GetDealsStore';
import { GetExpiredCouponsCache } from './types/GetExpiredCoupons';
import { GetTopStoresCache } from './types/GetTopStores';
import { ALL_STORES_QUERY } from './queries/allStores';
import { AllStoresQueryResponse } from './types/IGetAllStores';
import {
  GetTopStoresWithCouponsResponse,
  GetTopStoresWithCouponsTransform,
} from './types/GetTopStoresWithCoupons';
import TOP_STORES_WITH_COUPON from './queries/topStoresWithCoupons';

export const getTopStores = createNewEndpoint<
  FeaturedStoresResponse,
  FeaturedStoresVariables | void,
  FeaturedStores[],
  GetTopStoresCache
>({
  id: StoreActionType.GetTopStores,
  query: FEATURED_STORES_QUERY,
  method: GraphQlMethods.Get,
  transform: (response) => response.public.topStores,
  cacheTransform: ({ loading, data }) => ({
    loading,
    featuredStores: data,
  }),
});

export const getTopStoresWithCoupons = createNewEndpoint<
  GetTopStoresWithCouponsResponse,
  void,
  GetTopStoresWithCouponsTransform
>({
  id: StoreActionType.GetTopStoresWithCoupons,
  query: TOP_STORES_WITH_COUPON,
  method: GraphQlMethods.Get,
  transform: (response) => response.public.topStores,
});

export const getStoreBySlug = createNewEndpoint<
  StoreBySlugQueryResponse,
  { slug: string },
  Store
>({
  id: StoreActionType.GetStoreBySlug,
  query: STORE_SLUG_QUERY,
  method: GraphQlMethods.Get,
  transform: (response) => response.public.storeBySlug,
});

export const getExpiredCoupons = createNewEndpoint<
  StoreCouponsResponse,
  { id: string; isExpired: boolean },
  StoreCoupons,
  GetExpiredCouponsCache
>({
  id: StoreActionType.GetExpiredCoupons,
  query: STORE_COUPONS_QUERY,
  method: GraphQlMethods.Get,
  transform: (response) => response.public.storeCoupons,
  cacheTransform: ({ loading, data }) => ({
    loading,
    expiredCoupons: data,
  }),
});

export const getStoreCoupons = createNewEndpoint<
  StoreCouponsResponse,
  StoreCouponsQueryVariables,
  StoreCoupons
>({
  id: StoreActionType.GetStoreCoupons,
  query: STORE_COUPONS_QUERY,
  method: GraphQlMethods.Get,
  transform: (response) => response.public.storeCoupons,
});

export const getUserStoreRating = createNewEndpoint<
  StoreRatingQueryResponse,
  { id: string },
  number
>({
  id: StoreActionType.GetExpiredCoupons,
  query: USER_STORE_RATING_QUERY,
  method: GraphQlMethods.Get,
  transform: (response) => response.me.getStoreRating.userStoreRating.rating,
});

export const getAllStores = createNewEndpoint<
  AllStoresQueryResponse,
  { storeId: string } | void,
  AllStoresQueryResponse['public']['stores']
>({
  id: StoreActionType.GetTopStores,
  query: ALL_STORES_QUERY,
  method: GraphQlMethods.Get,
  transform: (response) => response.public.stores,
});

export const getStorePromotions = createNewEndpoint<
  StorePromotionsQueryResponse,
  StorePromotionsQueryVariables,
  StorePromotions,
  GetDealsStoreCache
>({
  id: StoreActionType.GetStorePromotions,
  query: STORE_PROMOTIONS_QUERY,
  method: GraphQlMethods.Get,
  transform: (response) => response.public.storePromotions,
  cacheTransform: (response, current) => {
    const { deals } = current || {};
    const { data, loading: isPaginationLoading } = response;
    const after = response?.variables?.after as string;

    if (after) {
      return {
        deals: {
          edges: [...(deals?.edges || []), ...(data?.edges || [])],
          pageInfo: data?.pageInfo as StorePromotionsPageInfo,
        },
        loading: isPaginationLoading,
      };
    }

    return {
      loading: response.loading,
      deals: response.data,
    };
  },
});

export const rateStore = createNewEndpoint<
  StoreRateMutationResponse,
  { input: StoreRateMutationParams },
  number
>({
  id: StoreActionType.PostRateStore,
  query: USER_STORE_RATE_MUTATION,
  method: GraphQlMethods.Post,
  transform: (response) => response.me.rateStore.userStoreRating.rating,
});
