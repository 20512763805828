const LOCALSTORAGE_ONBOARDING_STAGE = 'ONBOARDING_STAGE';
const LOCALSTORAGE_ONBOARDING_COMMUNITIES_SELECTED =
  'ONBOARDING_COMMUNITIES_SELECTED';

export function addOnboardingStageLocalStorage(stage: string) {
  return window.localStorage.setItem(LOCALSTORAGE_ONBOARDING_STAGE, stage);
}

export function removeOnboardingStageFromLocalStorage() {
  window.localStorage.removeItem(LOCALSTORAGE_ONBOARDING_STAGE);
}

export function getOnboardingStageLocalStorage() {
  if (typeof window !== 'undefined') {
    return window.localStorage.getItem(LOCALSTORAGE_ONBOARDING_STAGE);
  }
  return undefined;
}

const LOCALSTORAGE_TOOLTIP_ONBOARDING_ALERT_HOME =
  'TOOLTIP_ONBOARDING_ALERT_HOME';

export function addTooltipOnboardingAlertHomeLocalStorage(
  isResearcher: boolean
) {
  return window.localStorage.setItem(
    LOCALSTORAGE_TOOLTIP_ONBOARDING_ALERT_HOME,
    isResearcher.toString()
  );
}

export function removeTooltipOnboardingAlertHomeLocalStorage() {
  window.localStorage.removeItem(LOCALSTORAGE_TOOLTIP_ONBOARDING_ALERT_HOME);
}

export function getTooltipOnboardingAlertHomeLocalStorage() {
  if (typeof window !== 'undefined') {
    return window.localStorage.getItem(
      LOCALSTORAGE_TOOLTIP_ONBOARDING_ALERT_HOME
    );
  }
  return undefined;
}

export function addOnboardingCommunitiesLocalStorage(
  selectedCommunities: string[]
) {
  return window.localStorage.setItem(
    LOCALSTORAGE_ONBOARDING_COMMUNITIES_SELECTED,
    JSON.stringify(selectedCommunities)
  );
}

export function getOnboardingCommunitiesLocalStorage() {
  if (typeof window !== 'undefined') {
    const data = window.localStorage.getItem(
      LOCALSTORAGE_ONBOARDING_COMMUNITIES_SELECTED
    );
    try {
      return data ? (JSON.parse(data) as string[]) : [];
    } catch {
      return undefined;
    }
  }
  return undefined;
}
