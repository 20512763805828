import { EventCategory, OnboardingEvents } from '@/domain/events/analytics';
import { emitTrackingEvent } from '@/infra/analytics/emitTrackingEvent';

export enum StepTypes {
  INCENTIVE_STEP = 'INCENTIVE_STEP',
  VIDEO_STEP = 'VIDEO_STEP',
  VOTING_STEP = 'VOTING_STEP',
  CATEGORIES_STEP = 'CATEGORIES_STEP',
}

export enum UserType {
  RESEARCHER = 'RESEARCHER',
  OPPORTUNIST = 'OPPORTUNIST',
}

export const mapStepToEvent = {
  [StepTypes.VIDEO_STEP]: OnboardingEvents.OnboardWebStepVideo,
  [StepTypes.VOTING_STEP]: OnboardingEvents.OnboardWebStepVoting,
  [StepTypes.CATEGORIES_STEP]: OnboardingEvents.OnboardWebStepCategories,
  [StepTypes.INCENTIVE_STEP]: OnboardingEvents.OnboardWebStepCategories,
};

export const emitOnboardingEvent = (step: StepTypes, loggedUserId: string) => {
  emitTrackingEvent({
    category: EventCategory.Onboarding,
    name: mapStepToEvent[step],
    extra: {
      step,
      user_id: loggedUserId,
    },
  });
};

export const emitOnboardingVoteEvent = (loggedUserId: string) => {
  emitTrackingEvent({
    category: EventCategory.Onboarding,
    name: OnboardingEvents.OnboardWebVote,
    extra: {
      user_id: loggedUserId,
    },
  });
};

export const emitOnboardingSkipVideoEvent = (loggedUserId: string) => {
  emitTrackingEvent({
    category: EventCategory.Onboarding,
    name: OnboardingEvents.OnboardWebSkipVideo,
    extra: {
      user_id: loggedUserId,
    },
  });
};

export const emitOnboardingStartEvent = (loggedUserId: string) => {
  emitTrackingEvent({
    category: EventCategory.Onboarding,
    name: OnboardingEvents.OnboardWebStart,
    extra: {
      user_id: loggedUserId,
    },
  });
};

export const emitOnboardingEndEvent = (loggedUserId: string) => {
  emitTrackingEvent({
    category: EventCategory.Onboarding,
    name: OnboardingEvents.OnboardWebEnd,
    extra: {
      user_id: loggedUserId,
    },
  });
};

export const emitOnboardingCloseView = (
  loggedUserId: string,
  stepType: StepTypes
) => {
  emitTrackingEvent({
    category: EventCategory.Onboarding,
    name: OnboardingEvents.OnboardWebCloseView,
    extra: {
      user_id: loggedUserId,
      step: stepType,
    },
  });
};
