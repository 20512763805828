import { Community } from '@/domain/entities/Communities';
import { createNewEndpoint } from '../core/endpoint';
import { CommunitiesCache, CommunitiesResponse } from './types/Communities';
import { CommunitiesActionType } from './actionTypes';
import { COMMUNITIES_QUERY } from './queries/getCommunities';
import { GraphQlMethods } from '../core/fetch';
import {
  UserCommunityPreferencesResponse,
  UserCommunityPreferencesVariables,
} from './types/userCommunityPreferences';
import { USER_COMMUNITY_PREFERENCES_MUTATION } from './mutations/userCommunityPreferences';
import { COMMUNITY_BY_SLUG_QUERY } from './queries/getCommunityBySlug';
import {
  CommunityBySlugCache,
  CommunityBySlugResponse,
  CommunityBySlugVariables,
} from './types/Community';

export const getCommunities = createNewEndpoint<
  CommunitiesResponse,
  void,
  Community[],
  CommunitiesCache
>({
  id: CommunitiesActionType.GetCommunities,
  query: COMMUNITIES_QUERY,
  method: GraphQlMethods.Get,
  transform: (result) => result.public.communities,
  cacheTransform: ({ data, loading }) => ({
    communities: data,
    loading,
  }),
});

export const userCommunityPreferences = createNewEndpoint<
  UserCommunityPreferencesResponse,
  UserCommunityPreferencesVariables,
  boolean
>({
  id: CommunitiesActionType.PostUserCommunityPreferences,
  query: USER_COMMUNITY_PREFERENCES_MUTATION,
  method: GraphQlMethods.Post,
  transform: (result) => result.me.userCommunityPreferences,
});

export const getCommunityBySlug = createNewEndpoint<
  CommunityBySlugResponse,
  CommunityBySlugVariables,
  Community,
  CommunityBySlugCache
>({
  id: CommunitiesActionType.GetCommunities,
  query: COMMUNITY_BY_SLUG_QUERY,
  method: GraphQlMethods.Get,
  transform: (result) => result.public.communityBySlug,
  cacheTransform: ({ data, loading }) => ({
    community: data,
    loading,
  }),
});
