export const NOTIFICATION_FRAGMENT = `
  fragment NotificationFragment on Notification {
    id
    text
    link
    deal: offer {
      id
      price
      title
      discountFixed
      discountPercentage
      freeShipping
      kind
      status
      image {
        id
        url(height: 122)
      }
      store {
        name
        url
        slug
        image {
          id
          small: url(height: 122)
          medium: url(height: 238)
          large: url(height: 406)
          extraLarge: url(width: 1200)
          original: url
        }
      }
    }
    status
    createdAt
    imageUrl
  }
`;
