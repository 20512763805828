import React from 'react';
import { useRouter } from 'next/router';
import { Langs } from '@/locales';
import { useTranslation } from '../../../../hooks/useTranslation';
import { getAgreementUrl, getAgreementSlug } from '../../../../services/urls';
import { AgreementType } from '../../../../../domain/entities/Agreement';

type ExternalAgreementLinkProps = {
  agreementType: AgreementType;
  getAgreementLinkText?: (agreementType: AgreementType) => string;
};

const ExternalAgreementLink = ({
  agreementType,
}: ExternalAgreementLinkProps) => {
  const { t } = useTranslation('login', 'common');
  const { locale } = useRouter();
  const AgreementLink = (
    {
      [AgreementType.CODE_OF_CONDUCT]: t('login-code-of-conduct'),
      [AgreementType.PRIVACY_POLICY]: t('login-privacy-policy'),
      [AgreementType.TERMS_OF_SERVICE]: t('login-terms-of-service'),
    } as Record<AgreementType, string>
  )[agreementType];
  return (
    <a
      href={`${getAgreementUrl(
        getAgreementSlug(agreementType, t),
        locale as Langs
      )}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {AgreementLink}
    </a>
  );
};

export default ExternalAgreementLink;
