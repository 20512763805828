import { DEAL_TIMESTAMPS_FRAGMENT } from '../../fragments/deal';

const TOP_STORES_WITH_COUPON = `
  query TopStoresWithCoupons {
    public {
      topStores {
        id
        name
        url
        slug
        active: coupons(isExpired: false) {
          id
          title
          price
          discountFixed
          discountPercentage
          freeShipping
          status
          temperature
          couponCode
          kind
          sourceUrl
          commentCount
          isTracked
          timestamps {
            ...DealTimestampsFragment
          }
        }
        expired: coupons(isExpired: true) {
          id
          title
          price
          discountFixed
          discountPercentage
          freeShipping
          status
          temperature
          couponCode
          kind
          sourceUrl
          commentCount
          isTracked
          timestamps {
            ...DealTimestampsFragment
          }
        }
      }
    }
  }
  ${DEAL_TIMESTAMPS_FRAGMENT}
`;

export default TOP_STORES_WITH_COUPON;
