import {
  BodyLight,
  Button,
  Colors,
  H2Heavy,
  Icon,
  Theme,
} from '@pelando/components';
import { styled } from 'styled-components';

export const LogoutModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LogoutModalIcon = styled(Icon)`
  color: rgb(${Theme.colors.Brand});
  font-size: 40px;
  margin-bottom: 16px;
`;

export const LogoutModalTitle = styled.h1`
  ${H2Heavy}
  text-align: center;
`;

export const LogoutModalSubtitle = styled.h2`
  ${BodyLight}
  text-align: center;
  color: rgb(${Colors.Gray});
  margin: 4px 0 24px;
`;

export const LogoutModalButton = styled(Button)`
  height: 48px;
  width: 100%;
  margin-bottom: 8px;
`;
