import { ChevronRight } from '@pelando/fontawesome/icons';
import { useTranslation } from '@/presentation/hooks/useTranslation';
import { addDealOnboardingStageLocalStorage } from '@/infra/storage/dealOnboardingStorage';
import { useEffect } from 'react';
import { DealOnboardingContinueButton } from '../style';
import {
  Container,
  BigText,
  SmallText,
  WrapperText,
  ValuesImage,
  SmallTextStrong,
} from './style';
import { DealStepTypes, emitOnboardingDealsEndEvent } from '../consts';
import Translation from '../../Translation';

type ValuesStepType = {
  onClickContinue: () => void;
  loggedUserId: string;
};

export default function ValuesStep({
  onClickContinue,
  loggedUserId,
}: ValuesStepType) {
  const { t } = useTranslation('onboarding');

  useEffect(() => {
    addDealOnboardingStageLocalStorage(DealStepTypes.VALUES_STEP);
    emitOnboardingDealsEndEvent(loggedUserId);
  }, [loggedUserId]);

  return (
    <>
      <Container>
        <WrapperText>
          <SmallText>
            <Translation
              translation={t('onboarding-deal-values-step-text')}
              hasTag
            >
              Estamos preparando um Pelando <br />
              especial para você! Enquanto isso...
            </Translation>
          </SmallText>
          <BigText>{t('onboarding-deal-values-step-title')}</BigText>
          <SmallTextStrong>
            {t('onboarding-deal-values-step-subtitle')}
          </SmallTextStrong>
        </WrapperText>
        <ValuesImage />
      </Container>
      <DealOnboardingContinueButton
        icon={ChevronRight}
        iconPosition="right"
        onClick={onClickContinue}
      >
        {t('onboarding-button-lets-go')}
      </DealOnboardingContinueButton>
    </>
  );
}
