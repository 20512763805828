import { AvatarSize } from '@pelando/components';
import { isCashbackEnabled } from '@/presentation/services/featureFlags';
import {
  MobileHeader,
  MobileUserAvatar,
  MobileUserTitle,
  UserMenuTitle,
} from './style';

export type UserMenuHeaderProps = {
  nickname?: string;
  userImg?: string;
};

export function UserMenuHeader({ nickname, userImg }: UserMenuHeaderProps) {
  const cashbackEnabled = isCashbackEnabled();
  return (
    <>
      <MobileHeader data-cashback={cashbackEnabled}>
        <MobileUserAvatar
          src={userImg}
          alt={`avatar do usuário ${nickname}`}
          size={AvatarSize.LARGE}
        />
        <MobileUserTitle>{nickname}</MobileUserTitle>
      </MobileHeader>
      <UserMenuTitle key="menu-title">{nickname}</UserMenuTitle>
    </>
  );
}
