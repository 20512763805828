import {
  LaptopMobile,
  GamepadModern,
  SackDollar,
  Couch,
  Joystick,
  PersonBiking,
  Shirt,
  Books,
  HandHoldingHeart,
  FaceLaughSquint,
} from '@pelando/fontawesome/icons';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { Categories } from '../../../services/categories';

export type CategoriesOnboardingNew = Exclude<
  Categories,
  Categories.Automotive
>;

export const categoriesOnboardingNew: CategoriesOnboardingNew[] = [
  Categories.Eletronics,
  Categories.Game,
  Categories.Fashion,
  Categories.Home,
  Categories.Book,
  Categories.HealthAndBeauty,
  Categories.Market,
  Categories.Sports,
  Categories.Free,
  Categories.Finances,
  Categories.Baby,
  Categories.Service,
  Categories.Trips,
  Categories.Tools,
  Categories.Internet,
];

export const TitleCategoriesOnboardingNew: Record<
  CategoriesOnboardingNew,
  string
> = {
  [Categories.Eletronics]: 'onboarding-categories-title-electronics',
  [Categories.Game]: 'onboarding-categories-title-game',
  [Categories.Fashion]: 'onboarding-categories-title-fashion',
  [Categories.Home]: 'onboarding-categories-title-home',
  [Categories.Book]: 'onboarding-categories-title-book',
  [Categories.HealthAndBeauty]: 'onboarding-categories-title-beauty',
  [Categories.Market]: 'onboarding-categories-title-market',
  [Categories.Sports]: 'onboarding-categories-title-sports',
  [Categories.Free]: 'onboarding-categories-title-free',
  [Categories.Finances]: 'onboarding-categories-title-finances',
  [Categories.Baby]: 'onboarding-categories-title-baby',
  [Categories.Service]: 'onboarding-categories-title-service',
  [Categories.Trips]: 'onboarding-categories-title-trips',
  [Categories.Tools]: 'onboarding-categories-title-tools',
  [Categories.Internet]: 'onboarding-categories-title-internet',
};

export const getCommunityIconBySlug: {
  [index in string]: IconDefinition;
} = {
  'tech-lover': LaptopMobile,
  'mundo-gamer': GamepadModern,
  'tudo-gratis': SackDollar,
  'para-meu-lar': Couch,
  'achadinhos-importados': Joystick,
  'esporte-e-vida': PersonBiking,
  'para-elas': Shirt,
  'para-eles': Shirt,
  'cultura': Books,
  'para-minha-familia': HandHoldingHeart,
  'e-meme-ou-promo': FaceLaughSquint,
};
