const FEATURED_STORES_QUERY = `
  query FeaturedStoresQuery($storeId: ID, $categoryId: ID) {
    public {
      topStores(storeId: $storeId, categoryId: $categoryId) {
        id
        name
        url
        slug
        image {
          id
          url(width: 64)
        }
      }
    }
  }
`;

export default FEATURED_STORES_QUERY;
