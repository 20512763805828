import {
  NotificationStatus,
  SubscribedDevice,
} from '../../../domain/entities/Notification';
import { createNewEndpoint } from '../core/endpoint';
import { GraphQlMethods } from '../core/fetch';
import { PreventUnauthenticatedGuard } from '../core/guards/preventUnauthenticated';
import { NotificationsActionType } from './actionTypes';
import {
  SubscribeDeviceMutationVariables,
  SubscribeDeviceResponse,
} from './dtos/ISubscribeDeviceDTO';
import { READ_ALL_NOTIFICATIONS_MUTATION } from './mutations/readAllNotificationsMutation';
import { READ_NOTIFICATION_MUTATION } from './mutations/readNotificationMutation';
import { SUBSCRIBE_DEVICE_MUTATION } from './mutations/subscribeDeviceMutation';
import { NOTIFICATIONS_COUNT_QUERY } from './queries/getNotificationsCountQuery';
import { NOTIFICATIONS_QUERY } from './queries/getNotificationsQuery';
import {
  GetNotificationsCache,
  GetNotificationsResponse,
  GetNotificationsVariables,
  Notifications,
} from './types/GetNotifications';
import {
  GetNotificationsCountCache,
  GetNotificationsCountResponse,
} from './types/GetNotificationsCount';
import { ReadAllNotificationsResponse } from './types/ReadAllNotifications';
import {
  ReadNotificationResponse,
  ReadNotificationVariables,
} from './types/ReadNotification';

export const getNotifications = createNewEndpoint<
  GetNotificationsResponse,
  GetNotificationsVariables,
  Notifications,
  GetNotificationsCache
>({
  id: NotificationsActionType.GetNotifications,
  query: NOTIFICATIONS_QUERY,
  method: GraphQlMethods.Get,
  guards: [PreventUnauthenticatedGuard],
  transform: (result) => result.me.notifications,
  cacheTransform: ({ data, loading, variables }, current) => {
    const paginated = !!variables?.after;
    const currentEdges = current?.edges || [];
    const nextEdges = data?.edges || [];

    const edges = paginated ? [...currentEdges, ...nextEdges] : nextEdges;
    const pageInfo = data?.pageInfo;

    return {
      loading,
      edges,
      pageInfo,
    };
  },
});

export const getNotificationsCount = createNewEndpoint<
  GetNotificationsCountResponse,
  undefined | void,
  GetNotificationsCountResponse['me']['newNotificationsCount'],
  GetNotificationsCountCache
>({
  id: NotificationsActionType.GetNotificationsCount,
  query: NOTIFICATIONS_COUNT_QUERY,
  method: GraphQlMethods.Get,
  guards: [PreventUnauthenticatedGuard],
  transform: (result) => result.me.newNotificationsCount,
  cacheTransform: ({ data }, current) => ({
    count: data ?? current?.count,
  }),
});

export const readAllNotifications = createNewEndpoint<
  ReadAllNotificationsResponse,
  undefined | void,
  ReadAllNotificationsResponse['me']['readAllNotifications'],
  undefined | void
>({
  id: NotificationsActionType.ReadAllNotifications,
  query: READ_ALL_NOTIFICATIONS_MUTATION,
  method: GraphQlMethods.Post,
  guards: [PreventUnauthenticatedGuard],
  transform: (result) => result.me.readAllNotifications,
  cacheTransform: () => {
    const currentGetNotificationsCache = getNotifications.cache$.value;

    if (!currentGetNotificationsCache) return;

    const edges = currentGetNotificationsCache.edges || [];

    const updatedEdges = edges.map((edge) => ({
      ...edge,
      status: NotificationStatus.READ,
    }));

    getNotifications.cache$.next({
      ...currentGetNotificationsCache,
      edges: updatedEdges,
    });
  },
});

export const readNotification = createNewEndpoint<
  ReadNotificationResponse,
  ReadNotificationVariables,
  ReadNotificationResponse['me']['readNotification'],
  undefined | void
>({
  id: NotificationsActionType.ReadNotification,
  query: READ_NOTIFICATION_MUTATION,
  method: GraphQlMethods.Post,
  guards: [PreventUnauthenticatedGuard],
  transform: (result) => result.me.readNotification,
  cacheTransform: ({ variables }) => {
    const currentGetNotificationsCache = getNotifications.cache$.value;
    const notificationId = variables?.id;

    if (!currentGetNotificationsCache || !notificationId) return;

    const edges = currentGetNotificationsCache.edges || [];

    const updatedEdges = edges.map((edge) => {
      const isSameId = notificationId === edge.id;
      const status = isSameId ? NotificationStatus.READ : edge.status;

      return {
        ...edge,
        status,
      };
    });

    getNotifications.cache$.next({
      ...currentGetNotificationsCache,
      edges: updatedEdges,
    });
  },
});

export const subscribeDeviceMutation = createNewEndpoint<
  SubscribeDeviceResponse,
  SubscribeDeviceMutationVariables,
  SubscribedDevice
>({
  id: NotificationsActionType.SubscribeDeviceMutation,
  query: SUBSCRIBE_DEVICE_MUTATION,
  method: GraphQlMethods.Post,
  transform: (response) => response.me.subscribedDevice,
});
