export const COMMUNITIES_QUERY = `
query CommunitiesQuery {
        public{
            communities {
            id
            name
            slug
            description
            color
            image
            genderPreference
          }
    }
}
`;
