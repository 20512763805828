import { Badge, Container } from './styles';

export type CountBadgeIconProps = {
  count?: number;
  icon: React.ReactNode;
  className?: string;
};

export const CountBadgeIcon = ({
  count = 0,
  icon,
  className,
}: CountBadgeIconProps) => {
  const showCount = count > 0;
  const badgeText = count > 9 ? '9+' : count;

  return (
    <Container className={className}>
      {showCount && <Badge role="marquee">{badgeText}</Badge>}
      {icon}
    </Container>
  );
};
