import { useCallback, useRef, useState } from 'react';

const useInputManagement = (onValueChange: (value: string) => void) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isValid, setIsValid] = useState(true);

  const focus = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const blur = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.blur();
    }
  }, []);

  const clear = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.value = '';
      onValueChange('');
    }
  }, [onValueChange]);

  const checkValidity = useCallback(() => {
    if (inputRef.current && inputRef.current.value.length > 0) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, []);

  const clearValidity = useCallback(() => setIsValid(true), []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const changeHandler = useCallback(() => {
    if (inputRef.current) onValueChange(inputRef.current.value);
  }, [onValueChange]);

  return {
    inputRef,
    focus,
    clear,
    blur,
    changeHandler,
    isValid,
    checkValidity,
    clearValidity,
  };
};

export default useInputManagement;
