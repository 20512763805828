import { generateIndexArray } from '@pelando/components';
import { StepProgressBarContainer, StepProgressStepCircle } from './style';

type StepProgressBarProps = {
  currentStepIndex: number;
  numberOfSteps: number;
  className?: string;
};

export function StepProgressBar({
  currentStepIndex,
  numberOfSteps,
  className,
}: StepProgressBarProps) {
  const limitedNumberOfSteps = Math.min(numberOfSteps, 4);

  return (
    <StepProgressBarContainer className={className}>
      {generateIndexArray(limitedNumberOfSteps).map((index) => (
        <StepProgressStepCircle
          key={index}
          aria-pressed={
            index === currentStepIndex || (index === 3 && currentStepIndex >= 4)
          }
        />
      ))}
    </StepProgressBarContainer>
  );
}
