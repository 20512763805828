export const STORE_COUPONS_OFFER_FRAGMENT = `
  fragment StoreCouponsOffer on Offer {
    id
    title
    price
    discountFixed
    discountPercentage
    freeShipping
    status
    kind
    category {
      id
      title
      slug
    }
    temperature
    commentCount
    couponCode
    store {
      id
      name
      slug
      url
      image {
        id
        url
      }
    }
    image {
      id
      url(height: 238)
    }
    isTracked
    sourceUrl
    timestamps {
      createdAt
    }
  }
`;
