import { Caption, MediaQuery, Theme } from '@pelando/components';
import Link from 'next/link';
import styled from 'styled-components';

export const Container = styled.div`
  background-color: rgb(${Theme.colors.Kilo});
  border-radius: 8px;
  padding: 4px 8px;
  width: fit-content;
  margin: -8px 0 8px 24px;

  @media ${MediaQuery.SCREEN_MD_UP} {
    margin: -8px 0 12px 24px;
  }
`;

export const Content = styled(Link)`
  ${Caption}
  color: rgb(${Theme.colors.Brand});
  text-decoration: none;
`;
