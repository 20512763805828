import { ContentWithOverlay, useClickOutside } from '@pelando/components';
import { CircleHeart, Compass } from '@pelando/fontawesome/icons';
import { EventCategory, CommunityEvents } from '@/domain/events/analytics';
import { emitTrackingEvent } from '@/infra/analytics/emitTrackingEvent';
import { isShowCommunitiesEnabled } from '@/presentation/services/featureFlags';
import { useRef, useState } from 'react';
import dynamic from 'next/dynamic';
import { useTranslation } from '@/presentation/hooks/useTranslation';
import { ExploreIcon } from '../HeaderRightCornerContent/styles';
import { HeaderCategoriesItemContainer } from './style';

const ExploreListItemsEnhanced = dynamic(
  () => import('../../../ExploreListItems').then((mod) => mod.default),
  { ssr: true }
);

export const HeaderCategoriesItem = () => {
  const [openCategories, setOpenCategories] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const buttonCommunityRef = useRef<HTMLDivElement>(null);
  const isShowCommunitiesFeatureFlag = isShowCommunitiesEnabled();

  const { t } = useTranslation('header');

  const buttonText = isShowCommunitiesFeatureFlag
    ? 'communities-link-text'
    : 'categories-link-text';

  const iconName = isShowCommunitiesFeatureFlag ? CircleHeart : Compass;

  const handleOpenCommunityOrCategoriesList = () => {
    setOpenCategories(true);

    if (isShowCommunitiesFeatureFlag) {
      emitTrackingEvent({
        category: EventCategory.Community,
        name: CommunityEvents.ClickToGoBackToCommunities,
        extra: undefined,
      });
    }
  };

  const handleCloseCategoriesList = () => {
    setOpenCategories(false);
  };

  useClickOutside([containerRef], handleCloseCategoriesList);

  return (
    <>
      <div ref={buttonCommunityRef}>
        <HeaderCategoriesItemContainer
          onClick={handleOpenCommunityOrCategoriesList}
          key={1}
          icon={<ExploreIcon icon={iconName} />}
          text={t(buttonText)}
        />
      </div>

      <ContentWithOverlay open={openCategories}>
        <div ref={containerRef}>
          <ExploreListItemsEnhanced closeMenu={handleCloseCategoriesList} />
        </div>
      </ContentWithOverlay>
    </>
  );
};
