import { useCallback, useEffect } from 'react';
import {
  getNotificationCount$,
  clearNotificationsCountCache,
} from '../../../../infra/api/notifications/helpers/getNotificationsCount';
import { useApiCache } from '../../../hooks/useApiCache';
import {
  clearNotificationsCache,
  getNotificationsHelper,
} from '../../../../infra/api/notifications/helpers/getNotifications';
import {
  readAllNotifications,
  readNotification,
  getNotifications,
  getNotificationsCount,
} from '../../../../infra/api/notifications/endpoints';
import { ReadNotificationVariables } from '../../../../infra/api/notifications/types/ReadNotification';

export const useNotifications = () => {
  const { edges, loading, pageInfo } =
    useApiCache(getNotifications.cache$) || {};
  const { count } = useApiCache(getNotificationsCount.cache$) || {};

  useEffect(() => {
    const subscription = getNotificationCount$.subscribe();

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const handleGetNotifications = useCallback(getNotificationsHelper, []);
  const handleReadAllNotifications = useCallback(
    () => readAllNotifications.requestAsPromise(),
    []
  );

  const handleReadNotification = useCallback(
    (variables: ReadNotificationVariables) =>
      readNotification.requestAsPromise(variables),
    []
  );

  const handleClearNotifications = useCallback(clearNotificationsCache, []);
  const handleClearNotificationsCount = useCallback(
    clearNotificationsCountCache,
    []
  );

  return {
    edges: edges || [],
    loading: !!loading,
    pageInfo,
    count,
    getNotifications: handleGetNotifications,
    readAllNotifications: handleReadAllNotifications,
    readNotification: handleReadNotification,
    clearNotifications: handleClearNotifications,
    clearNotificationsCount: handleClearNotificationsCount,
  };
};
