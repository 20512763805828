import { ThemeName, ThemeOption } from '@pelando/components';
import { getProbablyLoggedCookie } from '../../../infra/auth/tokenManagement';

export const LOCAL_STORAGE_THEME_KEY = 'theme';
export const THEME_BODY_ATTR = 'data-theme';
export const PREFERENCE_THEME_MEDIA = '(prefers-color-scheme: dark)';
export const defaultLocale = (locale?: string) => locale === 'pt-BR';
export const getDarkTheme = (locale?: string) =>
  defaultLocale(locale) ? ThemeName.DARK : ThemeName.DARK_NINJA;
export const getLightTheme = (locale?: string) =>
  defaultLocale(locale) ? ThemeName.LIGHT : ThemeName.LIGHT_NINJA;

const haveLocalStorageSupport = () =>
  typeof window !== 'undefined' && 'localStorage' in window;

const haveMatchMediaSupport = () =>
  typeof window !== 'undefined' && 'matchMedia' in window;

export const getThemeByMatchMedia = (
  isDarkMode: boolean,
  locale?: string
): ThemeName => {
  if (isDarkMode) {
    return getDarkTheme(locale);
  }
  return getLightTheme(locale);
};

export const setTheme = (theme: ThemeName) => {
  document.body.setAttribute(THEME_BODY_ATTR, theme);
};

export const getSystemTheme = (locale?: string): ThemeName => {
  if (haveMatchMediaSupport()) {
    const { matches } = window.matchMedia(PREFERENCE_THEME_MEDIA);
    return getThemeByMatchMedia(matches, locale);
  }

  return getLightTheme(locale);
};

export const getThemeOption = (locale?: string): ThemeOption => {
  const lightTheme =
    locale === 'en-US' ? ThemeOption.LIGHT_NINJA : ThemeOption.LIGHT;
  if (haveLocalStorageSupport() && getProbablyLoggedCookie()) {
    const theme = window.localStorage.getItem(
      LOCAL_STORAGE_THEME_KEY
    ) as ThemeOption | null;
    return theme || lightTheme;
  }

  return lightTheme;
};

const getThemeNameByThemeOption = (
  option: ThemeOption,
  locale?: string
): ThemeName => {
  if (option === ThemeOption.AUTO) {
    return getSystemTheme(locale);
  }

  return option as unknown as ThemeName;
};

export const getThemeName = (locale?: string) =>
  getThemeNameByThemeOption(getThemeOption(locale), locale);

export const changeThemeWithoutSave = (
  option: ThemeOption,
  locale?: string
) => {
  const theme = getThemeNameByThemeOption(option, locale);
  setTheme(theme);
};

export const setThemeByUserOption = (option: ThemeOption, locale?: string) => {
  changeThemeWithoutSave(option, locale);

  if (haveLocalStorageSupport()) {
    window.localStorage.setItem(LOCAL_STORAGE_THEME_KEY, option);
  }
};

export const listenThemeChange = (locale?: string) => {
  const matchMediaListener = ({ matches }: MediaQueryListEvent) => {
    const userOption = getThemeOption(locale);
    if (userOption === ThemeOption.AUTO) {
      const theme = getThemeByMatchMedia(matches, locale);
      setTheme(theme);
    }
  };

  if (haveMatchMediaSupport()) {
    window.matchMedia(PREFERENCE_THEME_MEDIA).addListener(matchMediaListener);
  }
};

export const startThemeListening = (locale?: string) =>
  listenThemeChange(locale);
