import { STORE_COUPONS_OFFER_FRAGMENT } from '../../fragments/storeCouponsOffer';
import { STORE_FRAGMENT } from './fragments';

export const STORE_COUPONS_QUERY = `
  query StoreCouponsQuery($id: ID!, $isExpired: Boolean!) {
    public {
      storeCoupons(id: $id, isExpired: $isExpired) {
        store {
          ...StoreFragment
        }
        coupons {
          ...StoreCouponsOffer
        }
      }
    }
  }

  ${STORE_COUPONS_OFFER_FRAGMENT}
  ${STORE_FRAGMENT}
`;
