import { styled } from 'styled-components';
import {
  Colors,
  FontFamily,
  FontWeight,
  MediaQuery,
} from '@pelando/components';
import { DealOnboardingContainerSteps } from '../style';

export const WelcomeContainer = styled(DealOnboardingContainerSteps)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-image: url('/assets/onboardingDeal/welcome-image-mobile.png');
  background-repeat: no-repeat;
  background-position: center;
  width: 100vw;
  height: 100vh;
  background-size: cover;

  @media ${MediaQuery.SCREEN_MD_UP} {
    position: absolute;
    background-image: url('/assets/onboardingDeal/welcome-image.png');
    height: 100%;
    width: 100%;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  color: rgba(${Colors.White});
  bottom: 208px;
  @media ${MediaQuery.SCREEN_MD_UP} {
    position: unset;
    margin-top: 150px;
  }
`;

export const FlamedLogo = styled.div`
  display: flex;
  background-image: url('/assets/onboardingDeal/icon-logo.png');
  background-repeat: no-repeat;
  background-position: center;
  justify-content: center;
  width: 30px;
  height: 36px;
  background-size: cover;
  margin-bottom: 16px;
`;

export const TextOnboarding = styled.div`
  font-family: ${FontFamily.DM_SANS};
  font-weight: ${FontWeight.REGULAR};
  font-size: 2.5rem;
  line-height: 2.5rem;
`;
