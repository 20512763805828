import styled from 'styled-components';
import {
  MediaQuery,
  Theme,
  UserMenuInfoContainer,
  UserMenuInfoItem,
} from '@pelando/components';
import BaseCashbackIcon from '@/resources/supercashback/cashback-icon.svg?component';

export const UserMenuLoggedInfoContainer = styled(UserMenuInfoContainer)`
  margin-top: 0;
  margin-bottom: 16px;
`;

export const UserMenuUnloggedInfoContainer = styled(UserMenuInfoContainer)`
  margin-top: 0;
  margin-bottom: -8px;
`;

export const LogoutMenuInfoItem = styled(UserMenuInfoItem)`
  margin-top: 8px;

  @media ${MediaQuery.SCREEN_MD_UP} {
    margin-top: 0;
  }
`;

export const Divider = styled.div`
  border-bottom: 1px solid rgb(${Theme.colors.Golf});
  margin: 16px 24px;
`;

export const CashbackIcon = styled(BaseCashbackIcon)`
  width: 24px;
  height: 24px;
  fill: rgb(${Theme.colors.Brand});
`;
