import { STORE_FRAGMENT } from './fragments';

export const STORE_SLUG_QUERY = `
  query StoreBySlugQuery($slug: String!) {
    public {
      storeBySlug(slug: $slug) {
        ...StoreFragment
      }
    }
  }

  ${STORE_FRAGMENT}
`;
