export const SUBSCRIBE_DEVICE_MUTATION = `
  mutation SubscribeDeviceMutation($device: deviceInput!) {
    me {
      subscribeDevice(device: $device) {
        id
        fcmToken
        type
      }
    }
  }
`;
