import { CASHBACK_FRAGMENT_OFFER } from '../../fragments/cashback';
import {
  DEAL_TIMESTAMPS_FRAGMENT,
  PAGE_INFO_FRAGMENT,
} from '../../offers/queries/shared';

export const STORE_PROMOTIONS_QUERY = `
  query StorePromotionsQuery($storeId: ID!, $limit: Int, $after: String) {
    public {
      storePromotions(storeId: $storeId, limit: $limit, after: $after) {
        edges {
          id
          discountFixed
          discountPercentage
          freeShipping
          image {
            id
            url(height: 238)
          }
          price
          status
          temperature
          title
          isTracked
          kind
          commentCount
          store {
            url
            slug
            name
            image {
              id
              url(height: 238)
            }
          }
          timestamps {
            ...DealTimestampsFragment
          }

          ...CashbackFragmentOffer
        }
        pageInfo {
          ...PageInfoFragment
        }
      }
    }
  }

  ${CASHBACK_FRAGMENT_OFFER}

  ${DEAL_TIMESTAMPS_FRAGMENT}
  ${PAGE_INFO_FRAGMENT}
`;
