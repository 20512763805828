import {
  AvatarSkeleton,
  Button,
  CaptionHeavy,
  Icon,
  ListItem,
  ListItemIcon,
  MediaQuery,
  Skeleton,
  SmartLink,
  TextIconTooltip,
  Theme,
} from '@pelando/components';
import styled, { css } from 'styled-components';
import { HEADER_HEIGHT } from '../../styles';
import { UserMenu } from '../UserMenu';

export const HeaderRightCornerContainer = styled.div`
  display: flex;
  align-items: center;
  grid-area: right-corner;
`;

export const HeaderItemLink = styled(SmartLink)`
  text-decoration: none;
  color: rgb(${Theme.colors.Alpha});
`;

export const HeaderItem = styled(ListItem)`
  ${CaptionHeavy}
  ${ListItemIcon} {
    margin-right: 6px;
  }
  padding: 0px !important;
  margin-right: 8px;

  &:hover {
    background-color: initial;
  }

  @media ${MediaQuery.SCREEN_MD_DOWN} {
    display: none !important;
  }
`;

export const HeaderList = styled.ul``;

export const HeaderNotificationItem = styled.div`
  margin-right: 8px;

  @media ${MediaQuery.SCREEN_MD_DOWN} {
    [data-logged='true'] & {
      margin-right: 8px;
    }
  }

  @media ${MediaQuery.SCREEN_LG_UP} {
    margin-right: 10px;
  }
`;

const headerButtonStyles = css`
  width: 98px;
  height: 40px;
  border-radius: 24px;

  @media ${MediaQuery.SCREEN_LG_UP} {
    width: 112px;
  }
`;

export const HeaderButton = styled(Button)`
  ${headerButtonStyles}
  background-color: rgb(${Theme.colors.Brand});
  &:hover {
    background-color: rgb(${Theme.colors.Brand});
  }
`;

export const HeaderItemSkeleton = styled(Skeleton)`
  display: none;
  width: 96px;
  height: 36px;
  margin-right: 24px;
  border-radius: 8px;

  @media ${MediaQuery.SCREEN_LG_UP} {
    display: flex;
  }
`;

export const HeaderAvatarSkeleton = styled(AvatarSkeleton)`
  margin-right: 12px;
`;

export const HeaderIcon = styled(Icon)`
  && {
    font-size: 24px;
  }
`;

export const ExploreIcon = styled(HeaderIcon)`
  color: rgb(${Theme.colors.Brand});
`;

export const PostDealIcon = styled(HeaderIcon)`
  color: rgb(${Theme.colors.Brand});
`;

export const StoreDealIcon = styled(HeaderIcon)`
  color: rgb(${Theme.colors.Brand});
`;

export const AlertsIcon = styled(HeaderIcon)`
  color: rgb(${Theme.colors.Brand});
`;

export const CustomUserMenu = styled(UserMenu)`
  && {
    padding: 0;
    width: 276px;
    height: calc(100% - (${HEADER_HEIGHT}px + 140px));
    max-height: 799px;
    overflow: auto;
    top: ${HEADER_HEIGHT}px;
  }
  @media ${MediaQuery.SCREEN_SM} {
    && {
      top: 0;
      height: auto;
      max-height: none;
      width: 85%;
    }
  }
`;

export const TextIconTooltipStyle = styled(TextIconTooltip)`
  && {
    display: none;
    color: rgb(${Theme.colors.Juliet});

    & div {
      max-width: 268px;
    }

    @media ${MediaQuery.SCREEN_LG_UP} {
      display: flex;
    }
  }
`;
