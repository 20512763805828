import { useCallback, useState } from 'react';
import { getFeaturedStores } from '../../../../infra/api/stores/helpers/getFeaturedStores';
import { StoreItem } from '../components/options';

const useTopStoresMenuHandler = () => {
  const [featuredStores, setFeaturesStores] = useState<StoreItem[]>([]);

  const handleGetFeaturedStores = useCallback(async () => {
    if (featuredStores.length) return;
    const data = await getFeaturedStores();
    const topStores = data?.slice(0, 8).map((item) => {
      const { id, name, slug } = item;
      return { id, name, slug };
    });
    if (topStores) setFeaturesStores(topStores);
  }, [featuredStores.length]);

  return { featuredStores, handleGetFeaturedStores };
};

export default useTopStoresMenuHandler;
