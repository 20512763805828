import { getCurrencyStringByValue } from '@/presentation/services/strings';
import { Container, Content } from './style';

export type CashbackBalanceProps = {
  cashback: number;
  url: string;
};

export function CashbackBalance({ cashback, url }: CashbackBalanceProps) {
  const noCashback = 'R$ 0,00';

  return (
    <Container>
      <Content href={url}>
        Saldo de cashback:{' '}
        <strong>
          {cashback ? getCurrencyStringByValue(cashback) : noCashback}
        </strong>
      </Content>
    </Container>
  );
}
