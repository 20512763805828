import styled from 'styled-components';
import {
  Button,
  Colors,
  Icon,
  MediaQuery,
  ProgressBar,
} from '@pelando/components';
import { StepProgressBar } from '../../StepProgressBar';

type ProgressBarProps = {
  animate?: boolean;
};

export const StepsModalContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const CloseButtonIcon = styled(Icon)`
  color: rgb(${Colors.White});
  font-size: 32px;
  margin-left: auto;
  padding: 16px 16px 0 0;
  z-index: 3;

  &:hover {
    cursor: pointer;
  }
`;

export const MobileProgressBarContainer = styled.div`
  display: flex;
  position: fixed;
  justify-content: center;
  width: 100%;
  z-index: 3;
  top: 48px;
`;

export const MobileProgressBar = styled(ProgressBar)`
  width: 80px;
  height: 4px;

  background-color: rgba(${Colors.White}, 0.1);

  & div::before,
  & div::after {
    background-color: rgba(${Colors.White}, 0.5);

    height: 4px;
  }

  @media ${MediaQuery.SCREEN_MD_UP} {
    display: none;
  }
`;

export const StepsModalProgressBar = styled(StepProgressBar)<ProgressBarProps>`
  display: none;
  position: absolute;
  bottom: 60px;

  opacity: ${({ animate }) => (animate ? 1 : 0)};

  transition: opacity 2s ease-in-out;

  span {
    background-color: rgb(${Colors.White});
    opacity: 0.3;

    &[aria-pressed='true'] {
      opacity: 1;
      background-color: rgb(${Colors.White});
    }
  }

  opacity: ${({ animate }) => (animate ? 1 : 0)};

  transition: opacity 2s ease-in-out;

  @media ${MediaQuery.SCREEN_MD_UP} {
    display: flex;
    bottom: 60px;
  }
`;

export const StepVideoButton = styled(Button)`
  display: none;
  position: absolute;
  bottom: 96px;

  @media ${MediaQuery.SCREEN_MD_UP} {
    display: flex;
  }
`;
