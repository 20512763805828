export const ALL_STORES_QUERY = `
  query StoresQuery {
    public {
      stores {
        name
        slug
      }
    }
  }
`;
