import { Deal } from './Offer';
import { Store } from './Store';

export type NotificationId = string;

export enum NotificationStatus {
  NEW = 'NEW',
  READ = 'READ',
}

export type NotificationStore = {
  store:
    | (Omit<Store, 'image'> & {
        image: {
          id: string;
          small: string;
          medium: string;
          large: string;
          extraLarge: string;
          original: string;
        };
      })
    | null;
};

export type Notification = {
  id: NotificationId;
  text: string;
  link?: string;
  deal?: Pick<
    Deal,
    | 'id'
    | 'price'
    | 'title'
    | 'discountFixed'
    | 'discountPercentage'
    | 'freeShipping'
    | 'kind'
    | 'status'
    | 'image'
  > &
    NotificationStore;
  status: NotificationStatus;
  createdAt: string;
  imageUrl?: string;
};

export interface NotificationItem {
  id: string;
  text: string;
  link?: string;
  dealImage?: string;
  imageUrl?: string;
  status: NotificationStatus;
  createdAt: string;
  deal?: Pick<
    Deal,
    | 'price'
    | 'discountFixed'
    | 'discountPercentage'
    | 'freeShipping'
    | 'store'
    | 'id'
  >;
}

export enum DeviceType {
  APP = 'APP',
  BROWSER = 'BROWSER',
}

export interface deviceInput {
  fcmToken: string;
  type: DeviceType;
}

export type SubscribedDevice = {
  id: string;
  fcmToken: string;
  type: DeviceType;
};
