import { EventCategory, OnboardingEvents } from '@/domain/events/analytics';
import { emitTrackingEvent } from '@/infra/analytics/emitTrackingEvent';

export enum DealStepTypes {
  INCENTIVE_STEP = 'INCENTIVE_STEP',
  WELCOME_STEP = 'WELCOME_STEP',
  VOTING_STEP = 'VOTING_STEP',
  COMUNITIES_STEP = 'COMUNITIES_STEP',
  VALUES_STEP = 'VALUES_STEP',
}

export const emitOnboardingDealsStartEvent = (loggedUserId: string) => {
  emitTrackingEvent({
    category: EventCategory.Onboarding,
    name: OnboardingEvents.OnboardWebDealsStart,
    extra: {
      user_id: loggedUserId,
    },
  });
};

export const emitOnboardingDealsEndEvent = (loggedUserId: string) => {
  emitTrackingEvent({
    category: EventCategory.Onboarding,
    name: OnboardingEvents.OnboardWebDealsEnd,
    extra: {
      user_id: loggedUserId,
    },
  });
};

export const emitOnboardingDealsCloseViewEvent = (
  loggedUserId: string,
  stepType: DealStepTypes
) => {
  emitTrackingEvent({
    category: EventCategory.Onboarding,
    name: OnboardingEvents.OnboardWebDealsCloseView,
    extra: {
      user_id: loggedUserId,
      step: stepType,
    },
  });
};

export const emitOnboardingDealsVoteEvent = (loggedUserId: string) => {
  emitTrackingEvent({
    category: EventCategory.Onboarding,
    name: OnboardingEvents.OnboardWebDealsVote,
    extra: {
      user_id: loggedUserId,
    },
  });
};
