import { Langs } from '@/locales';
import { FOLLOWERS, FOLLOWING, MY_DATA, MY_PROFILE, PROFILE } from '../consts';

export const enum ProfilePageTabs {
  Deals = 'promocoes',
  Comments = 'comments',
}

export const enum ProfilePageFollowersTabs {
  Followers,
  Following,
}

type ProfilePageOptions = {
  tab?: ProfilePageTabs;
};

type ProfilePageFollowersOptions = {
  tab?: ProfilePageFollowersTabs;
};

const createProfilePageTabSearchParams = (tab?: ProfilePageTabs) => {
  const params = new URLSearchParams();

  if (tab === ProfilePageTabs.Comments) {
    params.set('tab', tab);
  }

  return params.toString() ? `?${params.toString()}` : '';
};

const createProfileFollowersTabSearchParams = (
  tab?: ProfilePageFollowersTabs,
  locale?: string
) => {
  const params = new URLSearchParams();

  if (tab === ProfilePageFollowersTabs.Following) {
    params.set('tab', FOLLOWING[locale as Langs]);
  }

  return params.toString() ? `?${params.toString()}` : '';
};

export const getProfileUrl = (
  nickname: string,
  options?: ProfilePageOptions,
  locale: string = 'pt-BR'
) => {
  const searchParams = createProfilePageTabSearchParams(options?.tab);
  return `${PROFILE[locale as Langs]}/${nickname}${searchParams}`;
};

export const getProfileFollowersUrl = (
  nickname: string,
  options?: ProfilePageFollowersOptions,
  locale: string = 'pt-BR'
) => {
  const searchParams = createProfileFollowersTabSearchParams(
    options?.tab,
    locale
  );
  return `${getProfileUrl(nickname, undefined, locale)}${
    FOLLOWERS[locale as Langs]
  }${searchParams}`;
};

export const getProfileFollowingUrl = (
  nickname: string,
  locale: string = 'pt-BR'
) =>
  getProfileFollowersUrl(
    nickname,
    { tab: ProfilePageFollowersTabs.Following },
    locale
  );

/**
 * Meu perfil ...
 */

export const getMyProfileUrl = (
  options?: ProfilePageOptions,
  locale: string = 'pt-BR'
) => {
  const searchParams = createProfilePageTabSearchParams(options?.tab);
  return `${MY_PROFILE[locale as Langs]}${searchParams}`;
};

export const getMyFollowersUrl = (
  options?: ProfilePageFollowersOptions,
  locale: string = 'pt-BR'
) => {
  const searchParams = createProfileFollowersTabSearchParams(
    options?.tab,
    locale
  );
  return `${getMyProfileUrl(undefined, locale)}${
    FOLLOWERS[locale as Langs]
  }${searchParams}`;
};

export const getMyFollowingUrl = (locale: string = 'pt-BR') =>
  `${getMyProfileUrl(undefined, locale)}${FOLLOWERS[locale as Langs]}?tab=${
    FOLLOWING[locale as Langs]
  }`;

export const getMyDataUrl = (locale: string = 'pt-BR') =>
  `${getMyProfileUrl(undefined, locale)}${MY_DATA[locale as Langs]}
  `;
