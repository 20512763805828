const USER_STORE_RATE_MUTATION = `
  mutation UserStoreRateMutation($input: rateStoreInput!) {
    me {
      rateStore(input: $input) {
        userStoreRating {
          id
          rating
        }
      }
    }
  }
`;

export default USER_STORE_RATE_MUTATION;
