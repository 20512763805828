import { getLoggedUser } from '../endpoints';
import {
  saveUserId,
  setUserIsProbablyLoggedCookie,
} from '../../../auth/tokenManagement';
import { AuthenticationStatus } from '../types/GetLoggedUser';
import { callHeldRequests } from '../../core/guards/preventUnauthenticated';
import { clearUnloggedId } from '../../core/tracker';

export const getLoggedUserHelper = () =>
  getLoggedUser
    .requestAsPromise()
    .then(({ data }) => {
      const userID = data?.id;
      const hasUser = !!data?.id;
      setUserIsProbablyLoggedCookie(hasUser);
      if (userID) {
        clearUnloggedId();
        callHeldRequests();
        saveUserId(userID);
      }
    })
    .catch(() => {
      getLoggedUser.cache$.next({
        loading: false,
        status: AuthenticationStatus.UNLOGGED,
      });
    });
