import { useEffect } from 'react';
import { getAlertsCount$ } from '../../../infra/api/alerts/helpers/getAlertsCount';
import { getAlertsCount } from '../../../infra/api/alerts/endpoints';
import { useApiCache } from '../useApiCache';

export const useAlertsCount = () => {
  const { count } = useApiCache(getAlertsCount.cache$) || {};

  useEffect(() => {
    const subscription = getAlertsCount$.subscribe();

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return {
    count: count ?? 0,
  };
};
