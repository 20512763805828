const USER_STORE_RATING_QUERY = `
  query UserStoreRatingQuery($id: ID!) {
    me {
      getStoreRating(id: $id) {
        userStoreRating {
          id
          rating
        }
      }
    }
  }
`;

export default USER_STORE_RATING_QUERY;
