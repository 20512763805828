const LOCALSTORAGE_DEAL_ONBOARDING_STAGE = 'DEAL_ONBOARDING_STAGE';

export function addDealOnboardingStageLocalStorage(stage: string) {
  return window.localStorage.setItem(LOCALSTORAGE_DEAL_ONBOARDING_STAGE, stage);
}

export function removeDealOnboardingStageFromLocalStorage() {
  window.localStorage.removeItem(LOCALSTORAGE_DEAL_ONBOARDING_STAGE);
}

export function getDealOnboardingStageLocalStorage() {
  if (typeof window !== 'undefined') {
    return window.localStorage.getItem(LOCALSTORAGE_DEAL_ONBOARDING_STAGE);
  }
  return undefined;
}
