import React from 'react';
import { Colors, Icon, rgbToHex } from '@pelando/components';
import {
  ComputerSpeaker,
  GamepadModern,
  House,
  ClothesHanger,
  Books,
  PrescriptionBottleMedical,
  CartShopping,
  PersonBiking,
  SackDollar,
  Wallet,
  BabyCarriage,
  Moped,
  Plane,
  Car,
  Shovel,
  Router,
} from '@pelando/fontawesome/icons';
import { IconDefinition } from '@pelando/fontawesome';
import transformToSlug from './strings/transformToSlug';
import { isHeaderCommunitiesDisabled } from './featureFlags';

const renderIcon = (icon: IconDefinition, color: string) => (
  <Icon icon={icon} style={{ fontSize: 24, color }} />
);

export enum ExploreCategories {
  ELECTRONICS = 'ELECTRONICS',
  GAME = 'GAME',
  HOME = 'HOME',
  FASHION = 'FASHION',
  BOOK = 'BOOK',
  HEALTH_AND_BEAUTY = 'HEALTH_AND_BEAUTY',
  MARKET = 'MARKET',
  SPORTS = 'SPORTS',
  FREE = 'FREE',
  FINANCES = 'FINANCES',
  BABY = 'BABY',
  SERVICE = 'SERVICE',
  TRIPS = 'TRIPS',
  AUTOMOTIVE = 'AUTOMOTIVE',
  TOOLS = 'TOOLS',
  INTERNET = 'INTERNET',
}

export type BaseCategories = Record<
  ExploreCategories,
  { icon: JSX.Element; title: string }
>;

const colorsOnOrder = [
  rgbToHex(Colors.Violet),
  rgbToHex(Colors.Blue),
  rgbToHex(Colors.Pink),
  rgbToHex(Colors.Alien),
  rgbToHex(Colors.Tangerine),
];

export const getCategorySlug = (title: string) => transformToSlug(title);

export const EXPLORE_CATEGORIES: BaseCategories = {
  [ExploreCategories.ELECTRONICS]: {
    icon: renderIcon(ComputerSpeaker, colorsOnOrder[0]),
    title: 'Eletrônicos',
  },
  [ExploreCategories.GAME]: {
    icon: renderIcon(GamepadModern, colorsOnOrder[1]),
    title: 'Games e PC Gamer',
  },
  [ExploreCategories.HOME]: {
    icon: renderIcon(House, colorsOnOrder[2]),
    title: 'Casa e Cozinha',
  },
  [ExploreCategories.FASHION]: {
    icon: renderIcon(ClothesHanger, colorsOnOrder[3]),
    title: 'Moda',
  },
  [ExploreCategories.BOOK]: {
    icon: renderIcon(Books, colorsOnOrder[4]),
    title: 'Livros, Filmes e Música',
  },
  [ExploreCategories.HEALTH_AND_BEAUTY]: {
    icon: renderIcon(PrescriptionBottleMedical, colorsOnOrder[0]),
    title: 'Saúde e Beleza',
  },
  [ExploreCategories.MARKET]: {
    icon: renderIcon(CartShopping, colorsOnOrder[1]),
    title: 'Supermercado',
  },
  [ExploreCategories.SPORTS]: {
    icon: renderIcon(PersonBiking, colorsOnOrder[2]),
    title: 'Esportes e Exercícios',
  },
  [ExploreCategories.FREE]: {
    icon: renderIcon(SackDollar, colorsOnOrder[3]),
    title: 'Grátis',
  },
  [ExploreCategories.FINANCES]: {
    icon: renderIcon(Wallet, colorsOnOrder[4]),
    title: 'Finanças',
  },
  [ExploreCategories.BABY]: {
    icon: renderIcon(BabyCarriage, colorsOnOrder[0]),
    title: 'Família e Crianças',
  },
  [ExploreCategories.SERVICE]: {
    icon: renderIcon(Moped, colorsOnOrder[1]),
    title: 'Delivery, Serviços e Assinaturas',
  },
  [ExploreCategories.TRIPS]: {
    icon: renderIcon(Plane, colorsOnOrder[2]),
    title: 'Viagens',
  },
  [ExploreCategories.AUTOMOTIVE]: {
    icon: renderIcon(Car, colorsOnOrder[3]),
    title: 'Carros e Motos',
  },
  [ExploreCategories.TOOLS]: {
    icon: renderIcon(Shovel, colorsOnOrder[4]),
    title: 'Ferramentas e Jardim',
  },
  [ExploreCategories.INTERNET]: {
    icon: renderIcon(Router, colorsOnOrder[0]),
    title: 'Planos de Internet, TV, Celular e Fixo',
  },
};

export const getExploreItemsSlugs = () =>
  Object.entries(EXPLORE_CATEGORIES).map(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ([_, { title }]) => getCategorySlug(title)
  );

export const getExploreItemsTitles = () =>
  Object.entries(EXPLORE_CATEGORIES).map(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ([_, { title }]) => title
  );

export const getExploreItemsTitleBySlug = (slug: string) => {
  const explorerItemsTitles = getExploreItemsTitles();

  return explorerItemsTitles.find((title) => getCategorySlug(title) === slug);
};

export const isExploreSlug = (slug: string) => {
  const explorerItemsTitles = getExploreItemsTitles();
  const isShowHeaderCommunitiesDisabled = isHeaderCommunitiesDisabled();

  if (isShowHeaderCommunitiesDisabled) return false;

  return explorerItemsTitles.some((title) => getCategorySlug(title) === slug);
};
