import { MediaQuery } from '@pelando/components';
import { useCallback, useEffect, useState } from 'react';
import useMediaQuery from '../../../../hooks/mediaQuery';

export type UseOpeningManagementParams = {
  currentPathname: string;
  onOpenChange: (opened: boolean) => void;
};

const useOpeningManagement = ({
  currentPathname,
  onOpenChange,
}: UseOpeningManagementParams) => {
  const isScreenSm = useMediaQuery(MediaQuery.SCREEN_SM);
  const isSearchPage = currentPathname && currentPathname === '/busca';
  const [opened, setOpened] = useState(false);

  const close = useCallback(() => {
    setOpened(false);
    onOpenChange(false);
  }, [onOpenChange]);

  const open = useCallback(() => {
    setOpened(true);
    onOpenChange(true);
  }, [onOpenChange]);

  useEffect(() => {
    setOpened(!isScreenSm);
  }, [isScreenSm]);

  useEffect(() => {
    if (!isSearchPage && isScreenSm) {
      close();
    }
  }, [close, isScreenSm, isSearchPage]);

  return { opened, close, open };
};

export default useOpeningManagement;
