import { GetNotificationsVariables } from '../types/GetNotifications';
import { getNotifications as getNotificationsEndpoint } from '../endpoints';

const NOTIFICATIONS_LIMIT = 10;

export const getNotificationsHelper = (variables?: GetNotificationsVariables) =>
  getNotificationsEndpoint.requestAsPromise({
    limit: NOTIFICATIONS_LIMIT,
    ...(variables || {}),
  });

export const clearNotificationsCache = () =>
  getNotificationsEndpoint.cache$.next({
    edges: [],
    pageInfo: undefined,
    loading: false,
  });
