import { useCallback, useEffect, useRef, useState } from 'react';

import {
  MediaQuery,
  SearchCommands,
  SearchNavigationEvent,
  SearchSuggestionsEvent,
  useClickOutside,
  useMediaQuery,
} from '@pelando/components';
import { NextRouter } from 'next/router';
import {
  SearchPageQueryParams,
  getSearchUrl,
} from '@/presentation/services/urls/search';
import { SearchType } from '@/presentation/pages/public/Search/types';
import { Langs } from '@/locales';
import { getDupelandoOriginFlag } from '@/presentation/services/dupelando';
import {
  EventCategory,
  GeneralProductEvents,
  SearchEvents,
} from '@/domain/events/analytics';
import { emitTrackingEvent } from '@/infra/analytics/emitTrackingEvent';
import { MostFrequentSearches } from '@/domain/entities/Search';
import { clearSuggestions } from '@/infra/api/search/helpers/searchSuggestions';
import useListenPathName from './useListenPathName';
import useOpeningManagement from './Search/useOpeningManagement';
import useInputManagement from './Search/useInputManagement';
import useRecentSearches from './Search/useRecentSearches';
import { SearchbarProps } from '../components/HeaderSearchBar';
import {
  AutoCompleteSuggestion,
  ProductSuggestion,
  SearchSuggestionPayload,
  SearchSuggestionType,
} from '../components/HeaderSearchBar/types';

export type SearchHandlerProps = {
  router: NextRouter;
  frequentSearches: MostFrequentSearches;
  searchedValue: string;
  products: ProductSuggestion[];
  suggestions: AutoCompleteSuggestion[];
  suggestionsLoading?: boolean;
  setDefaultValue: (searchedValue: string) => void;
  fetchSuggestions: (term: string) => void;
};

type ListsToShow = {
  recents: boolean;
  frequent: boolean;
  products: boolean;
  autocomplete: boolean;
};

const initialListsToShow: ListsToShow = {
  recents: true,
  frequent: true,
  products: false,
  autocomplete: false,
};

const SUGGESTIONS_SEARCH_LENGTH = 2;

const useSearchHandler = ({
  router,
  frequentSearches,
  searchedValue,
  products,
  suggestions,
  suggestionsLoading,
  setDefaultValue,
  fetchSuggestions,
}: SearchHandlerProps): SearchbarProps => {
  const [dropdownOpened, setDropdownOpened] = useState(false);
  const isSmallScreen = useMediaQuery(MediaQuery.SCREEN_MD_DOWN);

  const [listsToShow, setListsToShow] =
    useState<ListsToShow>(initialListsToShow);

  const {
    recentsList: recents,
    saveRecentItem,
    removeRecentItem,
  } = useRecentSearches();

  const isSearchPage = (currentPathname?: string) =>
    !!currentPathname?.includes('/busca');

  const searchRef = useRef<HTMLDivElement>(null);

  const handleValueChange = useCallback(
    (value: string) => {
      setDefaultValue(value);
      if (value) setDropdownOpened(true);

      const trimmedValue = value.trim();
      const shouldFetchSuggestions =
        trimmedValue.length >= SUGGESTIONS_SEARCH_LENGTH;

      if (shouldFetchSuggestions) {
        fetchSuggestions(trimmedValue);
        setListsToShow((current) => ({
          ...current,
          recents: false,
          frequent: false,
          autocomplete: true,
          products: true,
        }));
        return;
      }

      clearSuggestions();
      setListsToShow(initialListsToShow);
    },
    [setDefaultValue, fetchSuggestions]
  );

  const currentPathname = router.pathname;

  const {
    inputRef,
    clear,
    focus,
    blur,
    changeHandler,
    clearValidity,
    checkValidity,
  } = useInputManagement(handleValueChange);

  const handleOpenChange = useCallback(
    (opened: boolean) => {
      if (!opened) return;
      setDropdownOpened(opened);
      focus();
    },
    [focus]
  );

  const { close: closeSuggestions, open: openSuggestions } =
    useOpeningManagement({
      currentPathname,
      onOpenChange: handleOpenChange,
    });

  useListenPathName(currentPathname, clear, isSearchPage);

  const defaultGoBack = useCallback(() => {
    setDropdownOpened(false);

    if (dropdownOpened) {
      return;
    }

    if (currentPathname !== '/[slug]') {
      router.back();
      return;
    }

    router.push('/');
  }, [currentPathname, dropdownOpened, router]);

  const isInputOpen = Boolean(isSearchPage(currentPathname) || dropdownOpened);

  const { section } = router.query as {
    section?: SearchType;
  };

  const searchType = isSearchPage(currentPathname) ? section : SearchType.ALL;

  const handleSubmit = (value: string) => {
    if (!value.trim()) return;

    saveRecentItem(value);
    setDefaultValue(value);
    setDropdownOpened(false);
    setListsToShow(initialListsToShow);
    router.push(
      getSearchUrl({
        keyword: value,
        searchType,
        options: {
          [SearchPageQueryParams.ManualSearch]: true,
        },
        locale: router.locale as Langs,
      }),
      getSearchUrl({
        keyword: value,
        searchType,
        locale: router.locale as Langs,
      })
    );
  };

  const handleSubmitButtonClick = () => {
    const value = inputRef.current?.value.trim() || '';
    blur();

    if (!dropdownOpened && !value.length) {
      openSuggestions();
      focus();
      clearValidity();

      return;
    }

    if (dropdownOpened && value.length) {
      closeSuggestions();
    }

    checkValidity();

    if (value.length === 0) {
      focus();
      return;
    }
    setListsToShow(initialListsToShow);
    setDropdownOpened(false);
    handleSubmit(value);
  };

  const handleFocusChange = (focused: boolean) => {
    if (!focused) return;
    setDropdownOpened(true);
    setListsToShow(initialListsToShow);
    openSuggestions();
    emitTrackingEvent({
      category: EventCategory.Search,
      name: SearchEvents.ClickToTypeASearchTerm,
      extra: undefined,
    });
  };

  useClickOutside([searchRef, inputRef], () => {
    closeSuggestions();
    setDropdownOpened(false);
    setListsToShow(initialListsToShow);
  });

  const handleCommands: SearchCommands = ({ type, payload }) => {
    switch (type) {
      case SearchNavigationEvent.Focus:
        handleFocusChange(true);
        break;
      case SearchNavigationEvent.Blur:
        handleFocusChange(false);
        break;
      case SearchNavigationEvent.Change:
        changeHandler();
        break;
      case SearchNavigationEvent.Back:
        defaultGoBack();
        break;
      case SearchNavigationEvent.Close:
        closeSuggestions();
        setDropdownOpened(false);
        blur();
        setListsToShow(initialListsToShow);
        break;
      case SearchNavigationEvent.Open:
        if (isSmallScreen) {
          openSuggestions();
        } else {
          handleSubmitButtonClick();
        }
        break;
      case SearchNavigationEvent.Submit:
        handleSubmitButtonClick();
        break;

      case SearchSuggestionsEvent.SelectSuggestion: {
        const value = payload as SearchSuggestionPayload;

        switch (value?.type) {
          case SearchSuggestionType.RECENTS:
          case SearchSuggestionType.MOST_SEARCHED: {
            const { word } = value;

            const type =
              value.type === SearchSuggestionType.RECENTS
                ? 'recents'
                : 'most_searched';

            emitTrackingEvent({
              category: EventCategory.Search,
              name: SearchEvents.ClickOnSuggestedSearch,
              extra: {
                type,
                selected_term: word,
              },
            });

            if (word) handleSubmit(word);
            if (word && inputRef.current) inputRef.current.value = word;
            break;
          }

          case SearchSuggestionType.PRODUCTS: {
            emitTrackingEvent({
              category: EventCategory.Search,
              name: SearchEvents.ClickOnSuggestedSearch,
              extra: {
                type: 'product',
                search_term: searchedValue,
              },
            });
            emitTrackingEvent({
              category: EventCategory.GeneralProduct,
              name: GeneralProductEvents.ClickToGoToProductDetails,
              extra: {
                product_id: value.id,
                came_from_dpl: getDupelandoOriginFlag(),
              },
            });
            closeSuggestions();
            setDropdownOpened(false);
            setListsToShow(initialListsToShow);
            break;
          }

          case SearchSuggestionType.AUTOCOMPLETE: {
            const { suggestion } = value;
            emitTrackingEvent({
              category: EventCategory.Search,
              name: SearchEvents.ClickOnSuggestedSearch,
              extra: {
                type: 'autocomplete',
                selected_term: suggestion,
                search_term: searchedValue,
              },
            });

            setDropdownOpened(false);
            closeSuggestions();
            handleSubmit(suggestion);
            if (inputRef.current) inputRef.current.value = suggestion;

            break;
          }

          default:
        }

        break;
      }
      case SearchSuggestionsEvent.RemoveSuggestion: {
        const value = payload as SearchSuggestionPayload;

        if (value?.type === SearchSuggestionType.RECENTS) {
          const { word } = value;
          if (word) removeRecentItem(word);
        }

        break;
      }

      default:
        break;
    }
  };

  const getFrequentSearches = () =>
    listsToShow.frequent ? frequentSearches.slice(0, 4) : [];

  useEffect(() => {
    const haveSuggestions = listsToShow.autocomplete && !!suggestions.length;
    const haveProducts = listsToShow.products && !!products.length;

    if (
      !haveSuggestions &&
      !haveProducts &&
      !isSmallScreen &&
      !suggestionsLoading
    ) {
      setDropdownOpened(false);
    }
  }, [
    isSmallScreen,
    suggestionsLoading,
    listsToShow.products,
    listsToShow.autocomplete,
    products.length,
    suggestions.length,
  ]);

  return {
    inputRef,
    isInputOpen,
    searchRef,
    opened: dropdownOpened,
    recents: listsToShow.recents ? recents : [],
    defaultValue: searchedValue,
    commands: handleCommands,
    frequentSearches: getFrequentSearches(),
    suggestions: listsToShow.autocomplete ? suggestions : [],
    products: listsToShow.products ? products : [],
  };
};

export default useSearchHandler;
