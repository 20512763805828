import {
  CaptionHeavy,
  CaptionLight,
  Colors,
  DealImage,
  DealStamp,
  DealVoteButton,
  H1Heavy,
  H2Heavy,
  H3Heavy,
  MediaQuery,
  SmallLight,
  Theme,
} from '@pelando/components';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 8px;
  border-radius: 24px;
  border: 12px solid rgba(${Theme.colors.Lima}, 0.3);
  width: unset;

  @media ${MediaQuery.SCREEN_MD_UP} {
    padding: 24px 12px;
    width: 388px;
  }
`;

export const CardImage = styled(DealImage)`
  && {
    width: 119px;
    margin-bottom: 12px;
    height: 142px;

    @media ${MediaQuery.SCREEN_MD_UP} {
      width: 136px;
    }
  }
`;

export const VoteAndStoreWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 12px;
  margin-bottom: 12px;

  @media ${MediaQuery.SCREEN_MD_UP} {
    justify-content: center;
    gap: 24px;
  }
`;

export const VoteButtonStyled = styled(DealVoteButton)`
  border-color: rgb(${Theme.colors.Brand});
`;

export const Title = styled.h1`
  color: rgb(${Theme.colors.Alpha});
  ${H3Heavy}
  width: 330px;
  height: 24px;
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${MediaQuery.SCREEN_MD_UP} {
    ${H2Heavy}
    height: 52px;
  }
`;

export const DealStampStyled = styled(DealStamp)`
  color: rgb(${Colors.Graphite});
  ${H1Heavy}

  & > small {
    ${H3Heavy}
    margin-right: 4px;
  }

  & > span {
    ${H1Heavy}
  }
`;

export const WrapperStore = styled.div`
  display: flex;
  white-space: nowrap;
`;

export const DefaultTextStore = styled.div`
  ${CaptionLight}
  display: flex;
  align-items: center;
  color: rgb(${Colors.Gray});
  white-space: nowrap;
`;

export const StoreLabel = styled.div`
  ${CaptionHeavy}
  display: flex;
  align-items: center;
  color: rgb(${Colors.Graphite});
  margin-left: 4px;

  i {
    font-size: 20px;
    margin-left: 4px;
  }
`;

const animationTooltip = css`
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;

  &[data-is-visible='true'] {
    opacity: 1;
    visibility: visible;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  position: relative;
`;
export const Tooltip = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 48px;
  left: 50%;
  transform: translateX(-50%);
  ${animationTooltip}
`;

export const TooltipContent = styled.div`
  background-color: rgb(${Theme.colors.Brand});
  color: rgb(${Colors.White});
  border-radius: 8px;
  position: relative;
  padding: 10px;
  ${SmallLight}
  width: 172px;
  z-index: 2;
`;

export const TooltipDetail = styled.div`
  background-color: rgb(${Theme.colors.Brand});
  width: 24px;
  height: 24px;
  border-radius: 4px;
  transform: rotate(45deg);
  z-index: 1;
  margin-top: -16px;
`;
