export const LOCAL_STORAGE_THEME_KEY = 'theme';
export const THEME_ATTRIBUTE = 'data-theme';
export const PREFERENCE_THEME_MEDIA = '(prefers-color-scheme: dark)';

export const enum ThemeName {
  DARK = 'DARK',
  LIGHT = 'LIGHT',
  DARK_NINJA = 'DARK_NINJA',
  LIGHT_NINJA = 'LIGHT_NINJA',
}
export const enum ThemeOption {
  AUTO = 'AUTO',
  DARK = 'DARK',
  LIGHT = 'LIGHT',
  DARK_NINJA = 'DARK_NINJA',
  LIGHT_NINJA = 'LIGHT_NINJA',
}
