import { createContext, useContext } from 'react';
import { Subject } from 'rxjs';
import type { Observable } from 'rxjs';

export enum FixedAdStatus {
  READY = 'ready',
  WAITING = 'waiting',
}

export enum LayoutContextCommands {
  OpenSearchbar,
}

export const LayoutContextSubject$ = new Subject<LayoutContextCommands>();

export type LayoutContextType = {
  showingToast: boolean;
  googleClientId?: string;
  contextCommands: Observable<LayoutContextCommands>;
  openSearchbar: () => void;
};

export const DEFAULT_STATE: LayoutContextType = {
  showingToast: false,
  googleClientId: undefined,
  contextCommands: LayoutContextSubject$.asObservable(),
  openSearchbar: () =>
    LayoutContextSubject$.next(LayoutContextCommands.OpenSearchbar),
};

export const LayoutContext = createContext(DEFAULT_STATE);

export const useLayoutContext = () => useContext(LayoutContext);
