import { NOTIFICATION_FRAGMENT } from '../fragments/notificationFragment';

export const READ_NOTIFICATION_MUTATION = `
  mutation ReadNotificationMutation($id: ID!) {
    me {
      readNotification(id: $id) {
        ...NotificationFragment
      }
    }
  }

  ${NOTIFICATION_FRAGMENT}
`;
