import { useEffect } from 'react';

import { addDealOnboardingStageLocalStorage } from '@/infra/storage/dealOnboardingStorage';
import { ChevronRight } from '@pelando/fontawesome/icons';
import { useTranslation } from '@/presentation/hooks/useTranslation';
import {
  FlamedLogo,
  TextContainer,
  TextOnboarding,
  WelcomeContainer,
} from './style';
import { DealStepTypes, emitOnboardingDealsStartEvent } from '../consts';
import { DealOnboardingContinueButton } from '../style';
import Translation from '../../Translation';

const VIEWED_STEP_LOCALSTORAGE = 'viewedWelcomeStep';

type WelcomeStepProps = {
  onClickContinue: () => void;
  loggedUserId: string;
};

const WelcomeStep = ({ onClickContinue, loggedUserId }: WelcomeStepProps) => {
  const { t } = useTranslation('onboarding');

  useEffect(() => {
    if (!loggedUserId) return undefined;

    const viewedWelcomeStep = localStorage.getItem(VIEWED_STEP_LOCALSTORAGE);
    addDealOnboardingStageLocalStorage(DealStepTypes.WELCOME_STEP);

    if (!viewedWelcomeStep) {
      emitOnboardingDealsStartEvent(loggedUserId);
      localStorage.setItem(VIEWED_STEP_LOCALSTORAGE, 'true');
    }
    return () => {
      localStorage.removeItem(VIEWED_STEP_LOCALSTORAGE);
    };
  }, [loggedUserId]);

  return (
    <WelcomeContainer>
      <TextContainer>
        <FlamedLogo />
        <TextOnboarding>
          <Translation hasTag translation={t('onboarding-deals-welcome-title')}>
            Boas-vindas <br />
            ao <strong>Pelando!</strong>
          </Translation>
        </TextOnboarding>
      </TextContainer>
      <DealOnboardingContinueButton
        light
        icon={ChevronRight}
        iconPosition="right"
        onClick={onClickContinue}
      >
        {t('onboarding-button-start')}
      </DealOnboardingContinueButton>
    </WelcomeContainer>
  );
};

export default WelcomeStep;
