import { defaultLocale } from '@/presentation/services/theme';
import {
  LOCAL_STORAGE_THEME_KEY,
  PREFERENCE_THEME_MEDIA,
  THEME_ATTRIBUTE,
  ThemeName,
  ThemeOption,
} from '../../domain/entities/Theme';
import { getProbablyLoggedCookie } from '../auth/tokenManagement';

const darkTheme = (locale?: string) =>
  defaultLocale(locale) ? ThemeName.DARK : ThemeName.DARK_NINJA;

const ligthTheme = (locale?: string) =>
  defaultLocale(locale) ? ThemeName.LIGHT : ThemeName.LIGHT_NINJA;

export const setTheme = (theme: ThemeName) =>
  document.body.setAttribute(THEME_ATTRIBUTE, theme);

export const haveLocalStorageSupport = () =>
  typeof window !== 'undefined' && 'localStorage' in window;

export const haveMatchMediaSupport = () =>
  typeof window !== 'undefined' && 'matchMedia' in window;

export const getThemeByMatchMedia = (isDarkMode: boolean, locale?: string) => {
  if (isDarkMode) {
    return darkTheme(locale);
  }
  return ligthTheme(locale);
};

export function getSavedThemeOption(locale?: string): ThemeOption {
  if (!haveLocalStorageSupport() || !getProbablyLoggedCookie()) {
    return defaultLocale(locale) ? ThemeOption.LIGHT : ThemeOption.LIGHT_NINJA;
  }

  const theme = window.localStorage.getItem(
    LOCAL_STORAGE_THEME_KEY
  ) as ThemeOption | null;

  return theme || ThemeOption.LIGHT;
}

export const getSystemTheme = (): ThemeName => {
  if (!haveMatchMediaSupport()) {
    return ThemeName.LIGHT;
  }

  const { matches } = window.matchMedia(PREFERENCE_THEME_MEDIA);
  return getThemeByMatchMedia(matches);
};

export const getThemeNameByThemeOption = (option: ThemeOption): ThemeName =>
  option === ThemeOption.AUTO
    ? getSystemTheme()
    : (option as unknown as ThemeName);

export const getThemeName = () =>
  getThemeNameByThemeOption(getSavedThemeOption());

export const changeThemeWithoutSave = (option: ThemeOption) => {
  const theme = getThemeNameByThemeOption(option);
  setTheme(theme);
};

export const setThemeByUserOption = (option: ThemeOption) => {
  changeThemeWithoutSave(option);
  if (haveLocalStorageSupport()) {
    window.localStorage.setItem(LOCAL_STORAGE_THEME_KEY, option);
  }
};

export function listenThemeChange() {
  if (!haveMatchMediaSupport()) {
    return;
  }

  const matchMediaListener = ({ matches }: MediaQueryListEvent) => {
    const userOption = getSavedThemeOption();
    if (userOption === ThemeOption.AUTO) {
      const theme = getThemeByMatchMedia(matches);
      setTheme(theme);
    }
  };

  window.matchMedia(PREFERENCE_THEME_MEDIA).addListener(matchMediaListener);
}
