import transformToSlug from '../../strings/transformToSlug';

export const enum BlogPageQueryParams {
  Page = 'page',
}

export type GetBlogUrlOptions = {
  [BlogPageQueryParams.Page]?: number;
};

export const getBlogUrl = (options?: GetBlogUrlOptions): string => {
  const params = new URLSearchParams();

  if (!options) {
    return '/blog';
  }

  Object.entries(options).forEach(([key, value]) => {
    params.append(key, value.toString());
  });

  const queryString = params.toString();

  return `/blog${queryString ? `?${queryString}` : ''}`;
};

export type GetBlogPostUrlParams = {
  id: string;
  categorySlug: string;
  title: string;
};

export const getPostUrl = ({
  id,
  categorySlug,
  title,
}: GetBlogPostUrlParams): string =>
  `/blog/${id}/${categorySlug}/${transformToSlug(title)}`;

type GetBlogAuthorUrlParams = {
  id: string;
  name: string;
  page?: number;
};

export const getAuthorUrl = ({ id, name, page }: GetBlogAuthorUrlParams) => {
  const params = new URLSearchParams();

  if (page) {
    params.append(BlogPageQueryParams.Page, String(page));
  }

  const nameSlug = transformToSlug(name);

  const queryString = params.toString();
  const basePath = `/blog/autor/${id}/${nameSlug}`;

  return basePath + (queryString ? `?${queryString}` : '');
};
