import {
  BodyHeavy,
  BodyLight,
  Button,
  CaptionLight,
  Colors,
  H1Light,
  H2Light,
  H3Light,
  MediaQuery,
  Theme,
} from '@pelando/components';
import Image from 'next/image';
import { styled } from 'styled-components';

export const IncentiveContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  width: 100vw;
  height: 100vh;
  background-size: cover;

  background-image: url('/assets/onboarding/incentive-mobile-background.jpg');

  @media ${MediaQuery.SCREEN_MD_UP} {
    background-image: url('/assets/onboarding/incentive-desktop-background.jpg');
    height: 100%;
    width: 100%;
  }
`;

export const IncentiveImage = styled.img`
  width: 100vw;
`;

export const DownloadButton = styled(Button)`
  width: 100%;
  margin-top: 24px;
`;

export const IncentiveContinue = styled(Button)`
  width: 100%;
  height: 48px;
  margin-top: 48px;
  max-width: 280px;
  gap: 8px;

  @media ${MediaQuery.SCREEN_SM} {
    display: none;
  }
`;

export const IncentiveContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 32px 16px;

  border-radius: 16px 16px 0px 0px;
  bottom: 0;
  left: 0;
`;

export const IncentiveTitle = styled.span`
  ${H2Light};
  color: rgb(${Colors.White});
  text-align: center;
  max-width: 206px;
  margin-top: 32px;
  span {
    display: none;
  }

  @media ${MediaQuery.SCREEN_MD_UP} {
    ${H1Light};
    margin-top: unset;
    max-width: 602px;
    span {
      display: block;
    }
  }
`;

export const IncentiveTipsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
  margin-top: 8px;

  @media ${MediaQuery.SCREEN_MD_UP} {
    margin-top: 26px;
    flex-direction: row;
    gap: 8px;
  }
`;

export const IncentiveTip = styled.div`
  ${BodyLight}
  color: rgba(${Colors.White}, 1);
  border-radius: 24px;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  padding: 4px 20px 4px 12px;

  > i {
    margin-right: 8px;
    color: rgb(${Theme.colors.Brand});
  }

  @media ${MediaQuery.SCREEN_MD_UP} {
    ${BodyHeavy}
    background: rgb(${Colors.Graphite}, 1);
    padding: 8px 20px 8px 12px;

    &[data-strong='true'] {
      background: rgb(${Colors.Coal}, 1);
    }
  }
`;

export const IncentiveCardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 32px;

  @media ${MediaQuery.SCREEN_MD_UP} {
    margin-top: 24px;
    flex-direction: row;
    gap: 32px;
  }
`;

export const IncentiveCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 56px;
  gap: 16px;
  border-radius: 16px;
  padding: 12px 16px 12px 16px;
  background-color: rgba(${Colors.White}, 1);

  @media ${MediaQuery.SCREEN_MD_UP} {
    width: 296px;
    height: 142px;

    padding: 16px;

    cursor: pointer;
  }
`;

export const IncentiveCardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media ${MediaQuery.SCREEN_MD_UP} {
    margin-top: 8px;
  }
`;

export const CustomImage = styled(Image)`
  display: none;

  @media ${MediaQuery.SCREEN_MD_UP} {
    display: block;
  }
`;

export const MobileBanner = styled(Image)`
  margin-top: 44px;
  display: block;

  @media ${MediaQuery.SCREEN_MD_UP} {
    display: none;
  }
`;

export const DesktopSpan = styled.span`
  display: none;
  ${H3Light}

  @media ${MediaQuery.SCREEN_MD_UP} {
    display: block;
  }
`;

export const MobileSpan = styled.span`
  display: block;
  ${CaptionLight}

  @media ${MediaQuery.SCREEN_MD_UP} {
    display: none;
  }
`;

export const IconContent = styled.div`
  display: flex;
  align-items: center;
  column-gap: inherit;

  @media ${MediaQuery.SCREEN_MD_UP} {
    justify-content: space-between;
    column-gap: none;
  }
`;
