import { useRouter } from 'next/router';
import { getDeal } from '@/infra/api/deals/endpoints';
import { isCashbackEnabled } from '@/presentation/services/featureFlags';
import { OfferStatus } from '@/domain/entities/Offer';
import { getProductsDeals } from '@/infra/api/products/endpoints';
import { ProductDeal } from '@/domain/entities/Product';
import { useApiCache } from '../useApiCache';

const hasActiveCashbackDeal = (deals: ProductDeal[]) =>
  deals.some(({ cashback }) => (cashback?.percentage ?? 0) > 0);

export const useCashbackPageStatus = () => {
  const { pathname } = useRouter();
  const deal = useApiCache(getDeal.cache$);
  const { edges: deals = [] } = useApiCache(getProductsDeals.cache$) || {};

  const isDealPage = pathname.includes('/d/');
  const isProductPage = pathname.includes('/p/');
  const isValidPage = isDealPage || isProductPage;

  const isCashbackActive = isCashbackEnabled();
  const isDealActive = deal?.status === OfferStatus.ACTIVE;

  const dealCashbackPercentage = deal?.cashback?.percentage ?? 0;
  const cashbackPercentage = isDealActive ? dealCashbackPercentage : 0;

  const hasDealCashback = cashbackPercentage > 0 && isCashbackActive;
  const hasDealsWithCashback = hasActiveCashbackDeal(deals) && isCashbackActive;

  const hasCashback = (hasDealCashback || hasDealsWithCashback) && isValidPage;

  return { hasCashback };
};
