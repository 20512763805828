import { useEffect } from 'react';

function useListenPathName(
  currentPathname: string | undefined,
  clear: () => void,
  isSearchPage: (currentPathName: string | undefined) => boolean
) {
  useEffect(() => {
    if (!isSearchPage(currentPathname)) {
      clear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPathname]);
}

export default useListenPathName;
