import styled from 'styled-components';
import { Colors, Theme, SmallHeavy } from '@pelando/components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: none;
`;

export const Badge = styled.div`
  ${SmallHeavy}
  z-index: 1;
  display: flex;
  align-items: center;
  max-width: 21px;
  height: 16px;
  padding: 2px 3px;
  position: absolute;
  top: -7px;
  right: -10px;
  border-radius: 8px;
  background-color: rgb(${Colors.Red});
  border: 2px solid rgb(${Theme.colors.Juliet});

  font-size: 11px;
  line-height: 16px;
  color: rgb(${Colors.Porcelain});
`;
