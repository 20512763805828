import { logout } from '../endpoints';
import {
  removeProbablyLoggedCookie,
  removeAuthTokenFromLocalStorage,
  removeSavedUserId,
} from '../../../auth/tokenManagement';

export const logoutHelper = () =>
  logout.requestAsPromise().then(({ error }) => {
    if (error) return;
    removeProbablyLoggedCookie();
    removeAuthTokenFromLocalStorage();
    removeSavedUserId();
  });
