import { ArrowRightFromBracket } from '@pelando/fontawesome/icons';
import { useTranslation } from '@/presentation/hooks/useTranslation';
import {
  LogoutModalButton,
  LogoutModalContainer,
  LogoutModalIcon,
  LogoutModalSubtitle,
  LogoutModalTitle,
} from './style';

export const LogoutModal = ({
  logout,
  closeModal,
}: {
  logout: () => void;
  closeModal: () => void;
}) => {
  const { t } = useTranslation('common');

  return (
    <LogoutModalContainer>
      <LogoutModalIcon icon={ArrowRightFromBracket} />
      <LogoutModalTitle>{t('modal-logout-title')}</LogoutModalTitle>
      <LogoutModalSubtitle>{t('modal-logout-subtitle')}</LogoutModalSubtitle>
      <LogoutModalButton
        onClick={() => {
          logout();
          closeModal();
        }}
      >
        {t('modal-logout-button-title')}
      </LogoutModalButton>
      <LogoutModalButton onClick={closeModal} light>
        {t('back-button-title')}
      </LogoutModalButton>
    </LogoutModalContainer>
  );
};
