import { switchMap, timer } from 'rxjs';
import { getNotificationsCount as getNotificationsCountEndpoint } from '../endpoints';

const MINUTE_TO_MS = 60 * 1000;

export const getNotificationCount$ = timer(0, MINUTE_TO_MS).pipe(
  switchMap(() => getNotificationsCountEndpoint.request())
);

export const clearNotificationsCountCache = () =>
  getNotificationsCountEndpoint.cache$.next({
    count: 0,
  });
