import {
  BodyLight,
  Button,
  Colors,
  Display,
  H1Heavy,
  Icon,
  MediaQuery,
  Theme,
} from '@pelando/components';
import styled from 'styled-components';

export const OnboardingContainerSteps = styled.div`
  --background-color: ${Theme.colors.Brand};

  display: flex;
  top: 0;
  flex-direction: column;
  padding: 98px 8px 0;
  background-color: rgb(var(--background-color));
  min-width: 100vw;
  height: auto;
  min-height: 100vh;
  justify-content: center;
  position: fixed;

  @media ${MediaQuery.SCREEN_MD_UP} {
    position: unset;
    justify-content: flex-start;
    min-width: 850px;
    height: 100%;
    min-height: 524px;
    padding: 25px 65px 65px;
    align-items: center;
  }
`;

export const OnboardingTextContainer = styled.div`
  --text-color-var: ${Colors.White};
  display: block;
  position: fixed;
  top: 100px;
  align-items: flex-start;
  flex-direction: column;
  color: rgb(var(--text-color-var));
  margin-left: 16px;

  @media ${MediaQuery.SCREEN_MD_UP} {
    display: flex;
    position: unset;
    margin-left: unset;
    align-items: center;
    margin: 0 0 40px;
  }
`;

export const OnboardingTitle = styled.h1`
  ${Display}

  margin: 16px 30px 16px 0;

  span {
    color: rgb(${Theme.colors.Brand});
  }

  @media ${MediaQuery.SCREEN_MD_UP} {
    ${H1Heavy}
    margin: 0 0 8px;
  }
`;

export const OnboardingSubtitle = styled.h2`
  ${BodyLight}
  max-width: 256px;

  @media ${MediaQuery.SCREEN_MD_UP} {
    max-width: 100%;
  }
`;

export const HeaderIcon = styled(Icon)`
  font-size: 48px;
  color: rgb(${Colors.White});
  margin-bottom: 0;

  @media ${MediaQuery.SCREEN_MD_UP} {
    margin-bottom: 16px;
  }
`;

export const OnboardingContinueButton = styled(Button)`
  && {
    display: flex;
    position: fixed;
    bottom: 36px;
    min-width: 344px;
    width: 90%;
    height: 48px;

    @media ${MediaQuery.SCREEN_MD_UP} {
      position: absolute;
      max-width: 325px;
      bottom: 82px;
    }
  }
`;
