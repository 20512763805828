import React, {
  cloneElement,
  useCallback,
  useEffect,
  useId,
  useState,
} from 'react';
import { AvatarSize } from '@pelando/components';
import { ChevronDown } from '@pelando/fontawesome/icons';
import { LockScrollGlobally } from '@/presentation/styles/global';
import { useRouter } from 'next/router';
import { useTranslation } from '@/presentation/hooks/useTranslation';
import {
  CustomDropdownList,
  CustomDropdownTriggerButton,
  Overlay,
  UserMenuAvatar,
  UserMenuAvatarIcon,
  UserMenuContainer,
} from './style';
import { UserMenuHeader } from './UserMenuHeader';

export type UserMenuProps = {
  isLogged?: boolean;
  src?: string;
  alt?: string;
  className?: string;
  nickname: string;
  onOpen?: () => void;
  menuOptions: React.ReactNode[];
};

// TODO: Alterar a forma de utilizar o componente para evitar confusões com o className
export function UserMenu({
  isLogged,
  src,
  alt,
  nickname,
  onOpen,
  menuOptions,
}: UserMenuProps) {
  const { t } = useTranslation('header');
  const router = useRouter();
  const userMenuId = useId();
  const [open, setOpen] = useState(false);

  const options = [
    ...(isLogged
      ? [
          <UserMenuHeader
            key="user-menu-header"
            userImg={src}
            nickname={nickname}
          />,
        ]
      : []),
    ...menuOptions,
  ];

  const toggleOpenClick = useCallback(() => {
    setOpen((current) => {
      if (!current) onOpen?.();
      return !current;
    });
  }, [onOpen]);

  const close = useCallback(() => setOpen(false), []);

  useEffect(() => {
    router.events.on('routeChangeStart', close);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.events]);

  return (
    <UserMenuContainer>
      <CustomDropdownTriggerButton
        title={t('user-menu-title')}
        aria-haspopup="menu"
        aria-expanded={open}
        aria-controls={userMenuId}
        onClick={toggleOpenClick}
      >
        <UserMenuAvatar src={src} alt={alt} size={AvatarSize.LARGE} />
        <UserMenuAvatarIcon icon={ChevronDown} />
      </CustomDropdownTriggerButton>
      <Overlay data-opened={open} onClick={close} />
      <CustomDropdownList id={userMenuId} role="menu" data-opened={open}>
        {options.map((option) =>
          cloneElement(option as JSX.Element, {
            role: 'menuitem',
            onClick: close,
          })
        )}
      </CustomDropdownList>
      <LockScrollGlobally lock={open} />
    </UserMenuContainer>
  );
}
