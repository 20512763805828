import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { Icon, Theme } from '@pelando/components';
import {
  Books,
  Couch,
  FaceLaughSquint,
  GamepadModern,
  HandHoldingHeart,
  Joystick,
  LaptopMobile,
  PersonBiking,
  SackDollar,
  Shirt,
} from '@pelando/fontawesome/icons';
import transformToSlug from './strings/transformToSlug';
import { isHeaderCommunitiesDisabled } from './featureFlags';

const renderIcon = (icon: IconDefinition) => (
  <Icon
    icon={icon}
    style={{ fontSize: 24, color: `rgb(${Theme.colors.Brand})` }}
  />
);

export enum ExploreCommunities {
  Free = 'tudo-gratis',
  Imported = 'achadinhos-importados',
  TechLover = 'tech-lover',
  Game = 'mundo-gamer',
  Family = 'para-minha-familia',
  Sports = 'esporte-e-vida',
  ForWomen = 'para-elas',
  ForMen = 'para-eles',
  Culture = 'cultura',
  Home = 'para-meu-lar',
  Meme = 'e-meme-ou-promo',
}

export type BaseCommunities = Record<
  ExploreCommunities,
  {
    icon: JSX.Element;
    title: { [key: string]: string };
    slug?: { [key: string]: string };
  }
>;
export const EXPLORE_COMMUNITIES: BaseCommunities = {
  [ExploreCommunities.TechLover]: {
    icon: renderIcon(LaptopMobile),
    title: {
      'pt-BR': 'Tech Lover',
    },
    slug: {
      'pt-BR': ExploreCommunities.TechLover,
    },
  },
  [ExploreCommunities.Game]: {
    icon: renderIcon(GamepadModern),
    title: {
      'pt-BR': 'Mundo Gamer',
    },
    slug: {
      'pt-BR': ExploreCommunities.Game,
    },
  },
  [ExploreCommunities.Free]: {
    icon: renderIcon(SackDollar),
    title: {
      'pt-BR': 'Tudo grátis',
    },
    slug: {
      'pt-BR': ExploreCommunities.Free,
    },
  },
  [ExploreCommunities.Home]: {
    icon: renderIcon(Couch),
    title: {
      'pt-BR': 'Para meu lar',
    },
    slug: {
      'pt-BR': ExploreCommunities.Home,
    },
  },
  [ExploreCommunities.Imported]: {
    icon: renderIcon(Joystick),
    title: {
      'pt-BR': 'Achadinhos importados',
    },
    slug: {
      'pt-BR': ExploreCommunities.Imported,
    },
  },
  [ExploreCommunities.Sports]: {
    icon: renderIcon(PersonBiking),
    title: {
      'pt-BR': 'Esporte e vida',
    },
    slug: {
      'pt-BR': ExploreCommunities.Sports,
    },
  },
  [ExploreCommunities.ForWomen]: {
    icon: renderIcon(Shirt),
    title: {
      'pt-BR': 'Para elas',
    },
    slug: {
      'pt-BR': ExploreCommunities.ForWomen,
    },
  },
  [ExploreCommunities.ForMen]: {
    icon: renderIcon(Shirt),
    title: {
      'pt-BR': 'Para eles',
    },
    slug: {
      'pt-BR': ExploreCommunities.ForMen,
    },
  },
  [ExploreCommunities.Culture]: {
    icon: renderIcon(Books),
    title: {
      'pt-BR': 'Cultura',
    },
    slug: {
      'pt-BR': ExploreCommunities.Culture,
    },
  },
  [ExploreCommunities.Family]: {
    icon: renderIcon(HandHoldingHeart),
    title: {
      'pt-BR': 'Para minha família',
    },
    slug: {
      'pt-BR': ExploreCommunities.Family,
    },
  },
  [ExploreCommunities.Meme]: {
    icon: renderIcon(FaceLaughSquint),
    title: {
      'pt-BR': 'É meme ou promo?',
    },
    slug: {
      'pt-BR': ExploreCommunities.Meme,
    },
  },
};

export const getCommunitiesSlug = (title: string) => transformToSlug(title);

export const getExploreCommunitiesItemsTitles = (locale: string) =>
  Object.entries(EXPLORE_COMMUNITIES).map(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ([_, { title }]) => title[locale]
  );

export const getExploreCommunitiesItemsTitleBySlug = (
  slug: ExploreCommunities,
  locale: string
) => EXPLORE_COMMUNITIES[slug]?.title[locale];

export const getExploreCommunitiesItemsSlug = (
  slug: ExploreCommunities,
  locale: string
) => EXPLORE_COMMUNITIES[slug]?.slug?.[locale];

export const isExploreCommunitiesSlug = (
  slug: string
): slug is ExploreCommunities => {
  const isShowHeaderCommunitiesDisabled = isHeaderCommunitiesDisabled();

  if (isShowHeaderCommunitiesDisabled) return false;

  return !!EXPLORE_COMMUNITIES[slug as ExploreCommunities];
};
