import { Button, Colors, MediaQuery } from '@pelando/components';
import styled from 'styled-components';

export const DealOnboardingContinueButton = styled(Button)`
  && {
    display: flex;
    position: fixed;
    bottom: 24px;
    min-width: 325px;
    width: 90%;
    height: 48px;

    @media ${MediaQuery.SCREEN_MD_UP} {
      position: absolute;
      max-width: 325px;
      bottom: 82px;
    }
  }
`;

export const DealOnboardingContainerSteps = styled.div`
  display: flex;
  top: 0;
  flex-direction: column;
  padding: 98px 8px 0;
  background-color: rgb(${Colors.White});
  min-width: 100vw;
  height: auto;
  min-height: 100vh;
  justify-content: center;
  position: fixed;

  @media ${MediaQuery.SCREEN_MD_UP} {
    position: unset;
    justify-content: flex-start;
    min-width: 850px;
    height: 100%;
    min-height: 524px;
    padding: 25px 65px 65px;
    align-items: center;
  }
`;
