import { useTranslation } from '@/presentation/hooks/useTranslation';
import Image from 'next/image';
import { Icon } from '@pelando/components';
import { useEffect } from 'react';
import { addOnboardingStageLocalStorage } from '@/infra/storage/onboardingManagement';
import {
  Tag,
  BadgeDollar,
  Sparkles,
  ChevronRight,
} from '@pelando/fontawesome/icons';
import { getTelegramUrl, getWppUrl } from '@/presentation/services/urls';
import { useRouter } from 'next/router';
import { addDealOnboardingStageLocalStorage } from '@/infra/storage/dealOnboardingStorage';
import { emitTrackingEvent } from '@/infra/analytics/emitTrackingEvent';
import { EventCategory, OnboardingEvents } from '@/domain/events/analytics';
import { useCurrentUserCache } from '@/presentation/hooks/useCurrentUserCache';
import {
  CustomImage,
  DesktopSpan,
  IconContent,
  IncentiveCard,
  IncentiveCardContent,
  IncentiveCardsContainer,
  IncentiveContainer,
  IncentiveContent,
  IncentiveContinue,
  IncentiveTip,
  IncentiveTipsContainer,
  IncentiveTitle,
  MobileBanner,
  MobileSpan,
} from './style';
import { StepTypes } from '../consts';
import Translation from '../../Translation';

type IncentiveStepProps = {
  onClickContinue: () => void;
};

export enum SourceGroup {
  wpp = 'whats_app',
  telegram = 'telegram',
}

const IncentiveStep = ({ onClickContinue }: IncentiveStepProps) => {
  const { t } = useTranslation('onboarding');
  const { pathname } = useRouter();
  const isDealPage = pathname.includes('/d');
  const { loggedUser } = useCurrentUserCache();

  useEffect(() => {
    if (isDealPage) {
      addDealOnboardingStageLocalStorage(StepTypes.INCENTIVE_STEP);
      return;
    }

    addOnboardingStageLocalStorage(StepTypes.INCENTIVE_STEP);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDealPage]);

  const WPP_URL = getWppUrl();

  const TELEGRAM_URL = getTelegramUrl();

  return (
    <IncentiveContainer>
      <IncentiveContent>
        <MobileBanner
          role="presentation"
          aria-label="Mobile Onboarding Banner"
          alt="mobile-banner"
          src="/assets/onboarding/mobile-banner.png"
          width={262}
          height={224}
          loading="eager"
          priority
          fetchPriority="high"
        />
        <IncentiveTitle>
          <Translation translation={t('incentive-wpp-telegram-title')} hasTag>
            Participe do <strong>Canal do Pelando</strong>
            <span>e receba as melhores promoções e cupons!</span>
          </Translation>
        </IncentiveTitle>
        <IncentiveTipsContainer>
          <IncentiveTip>
            <Icon style={{ fontSize: 24 }} icon={BadgeDollar} />
            {t('incentive-wpp-telegram-tip-one')}
          </IncentiveTip>
          <IncentiveTip data-strong>
            <Icon style={{ fontSize: 24 }} icon={Tag} />
            {t('incentive-wpp-telegram-tip-two')}
          </IncentiveTip>
          <IncentiveTip>
            <Icon style={{ fontSize: 24 }} icon={Sparkles} />
            {t('incentive-wpp-telegram-tip-three')}
          </IncentiveTip>
        </IncentiveTipsContainer>
        <IncentiveCardsContainer>
          <IncentiveCard
            onClick={() => {
              emitTrackingEvent({
                category: EventCategory.Onboarding,
                name: OnboardingEvents.OnboardWebIncentiveChannel,
                extra: {
                  user_id: loggedUser?.id || '',
                  channel: SourceGroup.wpp,
                },
              });
              window.open(WPP_URL, '_blank');
            }}
          >
            <CustomImage
              role="presentation"
              alt="QRCode-wpp"
              src="/assets/onboarding/QRcodeWpp.png"
              width={110}
              height={110}
              loading="eager"
              priority
              fetchPriority="high"
            />
            <IncentiveCardContent>
              <IconContent>
                <Image
                  role="presentation"
                  alt=""
                  src="/assets/onboarding/whatsapp-logo.png"
                  width={32}
                  height={32}
                  loading="eager"
                  priority
                  fetchPriority="high"
                />

                <MobileSpan>
                  {t('incentive-wpp-telegram-link-label')}
                  <strong>WhatsApp</strong>
                </MobileSpan>
                <Icon style={{ fontSize: 24 }} icon={ChevronRight} />
              </IconContent>
              <DesktopSpan>
                {t('incentive-wpp-telegram-link-label')}
                <strong>WhatsApp</strong>
              </DesktopSpan>
            </IncentiveCardContent>
          </IncentiveCard>
          <IncentiveCard
            onClick={() => {
              emitTrackingEvent({
                category: EventCategory.Onboarding,
                name: OnboardingEvents.OnboardWebIncentiveChannel,
                extra: {
                  user_id: loggedUser?.id || '',
                  channel: SourceGroup.telegram,
                },
              });
              window.open(TELEGRAM_URL, '_blank');
            }}
          >
            <CustomImage
              role="presentation"
              alt="QRCode-telegram"
              src="/assets/onboarding/QRcodeTelegram.png"
              width={110}
              height={110}
              loading="eager"
              priority
              fetchPriority="high"
            />
            <IncentiveCardContent>
              <IconContent>
                <Image
                  role="presentation"
                  alt=""
                  src="/assets/onboarding/telegram-logo.png"
                  width={32}
                  height={32}
                  loading="eager"
                  priority
                  fetchPriority="high"
                />

                <MobileSpan>
                  {t('incentive-wpp-telegram-link-label')}
                  <strong>Telegram</strong>
                </MobileSpan>
                <Icon style={{ fontSize: 24 }} icon={ChevronRight} />
              </IconContent>
              <DesktopSpan>
                {t('incentive-wpp-telegram-link-label')}
                <strong>Telegram</strong>
              </DesktopSpan>
            </IncentiveCardContent>
          </IncentiveCard>
        </IncentiveCardsContainer>

        <IncentiveContinue light onClick={onClickContinue}>
          {t('incentive-wpp-telegram-continue-button')}
          <Icon style={{ fontSize: 24 }} icon={ChevronRight} />
        </IncentiveContinue>
      </IncentiveContent>
    </IncentiveContainer>
  );
};

export default IncentiveStep;
