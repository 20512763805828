import { MediaQuery } from '@pelando/components';
import { useEffect, useState } from 'react';

const useMediaQuery = (query: MediaQuery) => {
  const [match, setMatch] = useState<boolean>();
  useEffect(() => {
    setMatch(window.matchMedia(query).matches);
  }, [query]);

  return match;
};

export default useMediaQuery;
