import { ChevronRight } from '@pelando/fontawesome/icons';
import { useTranslation } from '@/presentation/hooks/useTranslation';
import { useEffect, useState } from 'react';
import { MediaQuery } from '@pelando/components';
import {
  addOnboardingCommunitiesLocalStorage,
  addOnboardingStageLocalStorage,
} from '@/infra/storage/onboardingManagement';
import useMediaQuery from '@/presentation/hooks/mediaQuery';
import {
  getCommunities,
  userCommunityPreferences,
} from '@/infra/api/communities/endpoints';
import { useApiCache } from '@/presentation/hooks/useApiCache';
import {
  addDealOnboardingStageLocalStorage,
  getDealOnboardingStageLocalStorage,
} from '@/infra/storage/dealOnboardingStorage';
import {
  CommunitiesContainer,
  CommunitiesContainerWrapper,
  CommunitiesStepContainer,
  CommunitiesSubtitle,
  CommunitiesTextContainer,
  CommunitiesTitle,
  CommunityCapsuleIcon,
  ContinueButton,
  SmokyButtonMobile,
  WrapperButton,
} from './style';
import { getCommunityIconBySlug } from './categoriesOnboardingNew';
import {
  StepTypes,
  emitOnboardingEndEvent,
  emitOnboardingEvent,
} from '../consts';
import Translation from '../../Translation';
import { DealStepTypes } from '../../DealOnboarding/consts';

type SelectedCommunities = string[];

export type CommunityStepIconType = {
  onClickContinue: () => void;
  loggedUserId: string;
  onFinished: () => void;
};

export default function CommunityStepIcon({
  onClickContinue,
  loggedUserId,
  onFinished,
}: CommunityStepIconType) {
  const { t } = useTranslation('onboarding');
  const isMobile = useMediaQuery(MediaQuery.SCREEN_MD_DOWN);
  const communitiesCache = useApiCache(getCommunities.cache$);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [selectedCommunities, setSelectedCommunities] =
    useState<SelectedCommunities>([]);
  const isOnboardingDeals = !!getDealOnboardingStageLocalStorage();

  const communityStepTitle = isMobile ? (
    <>
      <Translation translation={t('onboarding-communities-title-mobile')}>
        Quais <br />
        comunidades te
        <br /> interessam?
      </Translation>
    </>
  ) : (
    <>{t('onboarding-communities-title')}</>
  );

  const communityStepSubtitle = (
    <Translation translation={t('onboarding-communities-subtitle')} hasTag>
      Escolha no mínimo <strong>três comunidades</strong> para deixarmos o
      Pelando com a sua cara.
    </Translation>
  );

  useEffect(() => {
    getCommunities.requestAsPromise();
  }, []);

  useEffect(() => {
    if (isOnboardingDeals) {
      addDealOnboardingStageLocalStorage(DealStepTypes.COMUNITIES_STEP);
    } else {
      addOnboardingStageLocalStorage(StepTypes.CATEGORIES_STEP);
      emitOnboardingEvent(StepTypes.CATEGORIES_STEP, loggedUserId);
    }
  }, [isOnboardingDeals, loggedUserId]);

  useEffect(() => {
    const selectedCount = Object.values(selectedCommunities).filter(
      (isSelected) => isSelected
    ).length;
    setIsButtonEnabled(selectedCount >= 3);
  }, [selectedCommunities]);

  const getCommunitySelected = (communityId: string) =>
    selectedCommunities.includes(communityId);

  const toggleCommunity = (communityId: string) => {
    setSelectedCommunities((prevState) => {
      if (getCommunitySelected(communityId)) {
        return prevState.filter((item) => item !== communityId);
      }

      return [...prevState, communityId];
    });
  };

  const handleClickToContinue = () => {
    userCommunityPreferences.requestAsPromise({
      communityIds: selectedCommunities,
    });
    if (!isOnboardingDeals) {
      emitOnboardingEndEvent(loggedUserId);
      onFinished();
    }
    addOnboardingCommunitiesLocalStorage(selectedCommunities);
    onClickContinue();
  };

  return (
    <>
      <CommunitiesStepContainer data-is-deals={isOnboardingDeals}>
        <CommunitiesTextContainer>
          <CommunitiesTitle data-is-deals={isOnboardingDeals}>
            {communityStepTitle}
          </CommunitiesTitle>
          <CommunitiesSubtitle data-is-deals={isOnboardingDeals}>
            {communityStepSubtitle}
          </CommunitiesSubtitle>
        </CommunitiesTextContainer>
        <CommunitiesContainerWrapper data-is-deals={isOnboardingDeals}>
          <CommunitiesContainer>
            {/*  eslint-disable-next-line @typescript-eslint/no-unused-vars */}
            {communitiesCache?.communities?.map(({ id, slug, name }) => (
              <CommunityCapsuleIcon
                key={id}
                icon={getCommunityIconBySlug[slug]}
                title={name}
                active={getCommunitySelected(id)}
                onClick={() => toggleCommunity(id)}
                light={isOnboardingDeals}
              />
            ))}
          </CommunitiesContainer>
        </CommunitiesContainerWrapper>
        <WrapperButton>
          <SmokyButtonMobile
            disabled={!isButtonEnabled}
            data-is-deals={isOnboardingDeals}
          />
          <ContinueButton
            light={!isOnboardingDeals}
            icon={ChevronRight}
            iconPosition="right"
            responsive
            onClick={handleClickToContinue}
            disabled={!isButtonEnabled}
            data-is-deals={isOnboardingDeals}
          >
            {t('onboarding-button-continue')}
          </ContinueButton>
        </WrapperButton>
      </CommunitiesStepContainer>
    </>
  );
}
