import { ChevronRight, FireFlameCurved } from '@pelando/fontawesome/icons';
import { useTranslation } from '@/presentation/hooks/useTranslation';
import { useEffect } from 'react';
import { addDealOnboardingStageLocalStorage } from '@/infra/storage/dealOnboardingStorage';
import { DealOnboardingContinueButton } from '../style';
import VotingCard from './VotingCard';
import { Container, TextContainer, Title, VotingStepIcon } from './style';
import { DealStepTypes } from '../consts';
import Translation from '../../Translation';

type VotingStepProps = { onClickContinue: () => void };

export default function VotingStep({ onClickContinue }: VotingStepProps) {
  const { t } = useTranslation('onboarding');

  useEffect(() => {
    addDealOnboardingStageLocalStorage(DealStepTypes.VOTING_STEP);
  }, []);

  return (
    <>
      <Container>
        <TextContainer>
          <VotingStepIcon icon={FireFlameCurved} />
          <Title>
            <>
              <Translation
                translation={t('onboarding-voting-step-hot-title-mobile')}
              >
                Quanto mais <br /> quente, melhor!
              </Translation>
            </>
          </Title>
          <p>
            <Translation
              hasTag
              translation={t('onboarding-voting-step-hot-subtitle')}
            >
              O preço está bom?
              <strong>Clique no foguinho para esquentar.</strong>
            </Translation>
          </p>
        </TextContainer>
        <VotingCard onClickContinue={onClickContinue} />
      </Container>
      <DealOnboardingContinueButton
        icon={ChevronRight}
        iconPosition="right"
        onClick={onClickContinue}
      >
        {t('onboarding-button-continue')}
      </DealOnboardingContinueButton>
    </>
  );
}
