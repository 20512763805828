import { useState } from 'react';
import { Xmark } from '@pelando/fontawesome/icons';
import { useModal } from '@pelando/components';
import { useTranslation } from '@/presentation/hooks/useTranslation';
import { removeDealOnboardingStageFromLocalStorage } from '@/infra/storage/dealOnboardingStorage';
import { useApiCache } from '@/presentation/hooks/useApiCache';
import { getLoggedUser } from '@/infra/api/auth/endpoints';
import {
  isOnboardingIncentiveAppEnabled,
  isShowWelcomeStageOnboardDeals,
} from '@/presentation/services/featureFlags';
import { DealStepTypes, emitOnboardingDealsCloseViewEvent } from '../consts';
import WelcomeStep from '../WelcomeStep';
import { StepsModalContainer } from '../../NewOnboarding/OnboardingModal/style';
import {
  CloseButtonIcon,
  MobileProgressBarDeal,
  MobileProgressBarDealContainer,
  StepsModalProgressBarDeal,
} from './style';
import CommunityStepIcon from '../../NewOnboarding/CategoryStepIcon/communitiesStep';
import VotingStep from '../VotingStep';
import ValuesStep from '../ValuesStep';
import IncentiveStep from '../../NewOnboarding/AppIncentiveTypeAStep';

type DealOnboardingModalProps = {
  onFinished: () => void;
  stepTypeDefault?: DealStepTypes;
};

export function DealOnboardingModal({
  onFinished,
  stepTypeDefault,
}: DealOnboardingModalProps) {
  const showStepWelcome = isShowWelcomeStageOnboardDeals();

  const onboardingIncentiveAppEnabled = isOnboardingIncentiveAppEnabled();

  const onboardingModalSteps = [
    DealStepTypes.INCENTIVE_STEP,
    ...(showStepWelcome ? [DealStepTypes.WELCOME_STEP] : []),
    DealStepTypes.VOTING_STEP,
    DealStepTypes.COMUNITIES_STEP,
    DealStepTypes.VALUES_STEP,
  ];
  const { closeModal } = useModal();

  const { t } = useTranslation('onboarding');
  const { loggedUser } = useApiCache(getLoggedUser.cache$) || {};
  const loggedUserId = loggedUser?.id || '';

  const stepIndexDefault = onboardingModalSteps.findIndex(
    (step) => step === stepTypeDefault
  );

  const initialIndex = onboardingIncentiveAppEnabled ? 0 : 1;

  const [currentStep, setCurrentStep] = useState<DealStepTypes>(
    onboardingModalSteps[stepIndexDefault > 0 ? stepIndexDefault : initialIndex]
  );

  const numberOfSteps = onboardingModalSteps.length;
  const currentStepIndex = onboardingModalSteps.findIndex(
    (step) => step === currentStep
  );

  const hasWelcomeStep =
    currentStepIndex === 0 &&
    (currentStep === DealStepTypes.INCENTIVE_STEP ||
      currentStep === DealStepTypes.WELCOME_STEP);

  const handleOnClickToContinue = () => {
    if (currentStepIndex < numberOfSteps - 1) {
      const nextStep = onboardingModalSteps[currentStepIndex + 1];
      setCurrentStep(nextStep);
    } else {
      onFinished();
    }
  };

  const handleOnboardingClose = () => {
    removeDealOnboardingStageFromLocalStorage();
    closeModal();
    if (currentStep !== DealStepTypes.VALUES_STEP)
      emitOnboardingDealsCloseViewEvent(loggedUserId, currentStep);
  };

  const StepsContents: {
    [index in DealStepTypes]: JSX.Element;
  } = {
    INCENTIVE_STEP: <IncentiveStep onClickContinue={handleOnClickToContinue} />,
    WELCOME_STEP: (
      <WelcomeStep
        onClickContinue={handleOnClickToContinue}
        loggedUserId={loggedUserId}
      />
    ),
    VOTING_STEP: <VotingStep onClickContinue={handleOnClickToContinue} />,
    COMUNITIES_STEP: (
      <CommunityStepIcon
        onClickContinue={handleOnClickToContinue}
        onFinished={handleOnClickToContinue}
        loggedUserId={loggedUser?.id || ''}
      />
    ),
    VALUES_STEP: (
      <ValuesStep
        onClickContinue={handleOnboardingClose}
        loggedUserId={loggedUserId}
      />
    ),
  };

  const hasWelcomeStepPercent = showStepWelcome ? 4 : 3;

  const progressBarPercent =
    ((currentStepIndex + 1) / hasWelcomeStepPercent) * 100;

  return (
    <StepsModalContainer>
      <CloseButtonIcon
        icon={Xmark}
        aria-label={t('onboarding-modal-close-button-aria-label')}
        role="button"
        onClick={handleOnboardingClose}
        data-is-welcome-step={hasWelcomeStep}
      />

      <MobileProgressBarDealContainer>
        <MobileProgressBarDeal
          percent={progressBarPercent}
          data-is-incentive-step={currentStep === DealStepTypes.INCENTIVE_STEP}
        />
      </MobileProgressBarDealContainer>

      {StepsContents[currentStep]}
      <StepsModalProgressBarDeal
        currentStepIndex={currentStepIndex}
        numberOfSteps={numberOfSteps}
        welcomeStep={hasWelcomeStep}
      />
    </StepsModalContainer>
  );
}
