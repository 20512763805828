export const STORE_FRAGMENT = `
  fragment StoreFragment on Store {
    id
    name
    url
    slug
    image {
      id
      url(height: 120)
    }
    status
    top
    couponsCount
    offersCount
    noIndex
    seoContent {
      coupon {
        h1Title
        htmlTitle
        metaDescription
        shortDescription
        footerDescription
      }
    }
    ratingInfo {
      ratingCount
      ratingMean
    }
  }
`;
