const CONTEXT_ONBOARDING_VIDEO_ALERT = 'CONTEXT_ONBOARDING_VIDEO_ALERT';

export function addContextOnboardingVideoAlertLocalStorage() {
  return window.localStorage.setItem(CONTEXT_ONBOARDING_VIDEO_ALERT, 'true');
}

export function removeContextOnboardingVideoAlertFromLocalStorage() {
  window.localStorage.removeItem(CONTEXT_ONBOARDING_VIDEO_ALERT);
}

export function getContextOnboardingVideoAlertLocalStorage() {
  if (typeof window !== 'undefined') {
    return window.localStorage.getItem(CONTEXT_ONBOARDING_VIDEO_ALERT);
  }
  return undefined;
}
